import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { UtilService } from "./services/util.service";
import { AuthService } from "./services/auth.service";

/**
 * This interceptor has two main tasks:
 * 1) Send http credentials (auth cookies etc.) Only with requests that have the backend as a destination.
 * 2) If the request is to the backend, parse the response body and convert all date strings into JS date objects.
 *    This is an easy and performant way of dealing with dates in JSON.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private utilService: UtilService,
    private authService: AuthService
  ) {}
  // This was previously used to inject the API auth token to every API request.
  // Because we switched to using HttpOnly cookies for JWT auth,
  // it now makes sure that Cookies are sent with every request to the backend, and only to there!

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Don't send any credentials if the user is not logged in.
    // This is to prevent API calls from being made with credentials shortly after
    // The user wanted to log out.
    if (this.authService.currentAuthState) {
      // Matcher that filters out requests that are sent to the API.
      // The backend URL is included here so the regex should look something like
      // /\bhttp://localhost:8081[a-zA-Z.]*\b/g
      // Only differing by the URL part.
      // This is a simple but effective XSRF protection

      // TODO: verify that these regex are working with older browsers.
      // The URLs are not escaped and we know how much IE likes that.
      const httpScheme =
        environment.requestScheme === "secure" ? "https://" : "http://";
      const wsScheme =
        environment.requestScheme === "secure" ? "wss://" : "ws://";

      const httpRegex = new RegExp(
        `\\b^${httpScheme}${environment.backendUrl}[a-zA-Z.]*\\b`,
        "g"
      );
      const wsRegex = new RegExp(
        `\\b^${wsScheme}${environment.backendUrl}[a-zA-Z.]*\\b`,
        "g"
      );

      // Verify this is working with https://regexr.com/52h9g (on the "tests" tab)
      if (
        request.url.match(httpRegex) != null ||
        request.url.match(wsRegex) != null
      ) {
        // If the request URL starts with the backend url, include credentials.
        request = request.clone({
          withCredentials: true,
        });

        // Only perform the parsing if the request was sent to the backend
        return next.handle(request).pipe(
          tap(
            (event: HttpEvent<any>) => {              
              if (event instanceof HttpResponse) {
                const body = event.body;
                this.utilService.convertToDate(body);
              }
            },
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                if (err.status !== 401) {
                  console.groupCollapsed();
                  console.error("Error at ApiInterceptor");
                  console.log(err);
                  console.groupEnd();
                }
              }
            }
          )
        );
      }
    }

    // Don't touch the request if it was to a third-party URL.
    return next.handle(request);
  }
}
