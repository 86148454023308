import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

import { DataStore } from "src/app/types/data-store.model";

import {
  DefaultParameterResponse,
  DefaultParameterUpdate,
  ProcessParameter,
  SolidParameterConcentration,
} from "src/app/types/process-parameters.model";

import { environment } from "src/environments/environment";

import { UnitService } from "../unit.service";
import { ConversionService } from "../conversion.service";
import {
  ParameterTemplate,
  ParameterTemplateCreate,
  ParameterTemplateUpdate,
} from "src/app/types/parameter-template.model";
import { UtilService } from "../util.service";
import { SequencesService } from "./sequences.service";

class ParameterStore extends DataStore<ProcessParameter> { }

@Injectable({
  providedIn: "root",
})
export class ProcessParametersService {
  private _templates$: BehaviorSubject<
    ParameterTemplate[]
  > = new BehaviorSubject([]);

  public readonly templates: Observable<ParameterTemplate[]> = combineLatest([
    this._templates$,
    this.unitService.preferredWeightUnit,
  ]).pipe(
    // Update values when the preferred weight unit changes
    map(([templates, preferredWeightUnit]) => {
      return templates?.map((template) =>
        this.conversionService.toParameterTemplate(template)
      );
    }),
    distinctUntilChanged(),
    shareReplay()
  );

  private _parameterStore$: ParameterStore = new ParameterStore();

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  /**
   * Get all solids as an observable
   */
  public readonly parameters: Observable<ProcessParameter[]> = combineLatest([
    this._parameterStore$.getAllObservable(),
    this.unitService.preferredWeightUnit,
  ]).pipe(
    map(([parameters, preferredWeightUnit]) => {
      return parameters?.map((i) =>
        this.conversionService.toParameter(i)
      );
    }),
    distinctUntilChanged(),
    shareReplay()
  );

  constructor(
    private http: HttpClient,
    private unitService: UnitService,
    private conversionService: ConversionService,
    private utilService: UtilService,
    private sequencesService: SequencesService
  ) { }
  /**
   * Get all currently fetched solids
   */
  public get currentParameters() {
    return this._parameterStore$
      .getAll()
      .map((i) => this.conversionService.toParameter(i));
  }

  /**
   * Find a parameter by id, returns "null" if none could be found
   */
  public findParameter(id: number): ProcessParameter {
    return this.currentParameters.find(i => i.Id === id);
  }

  /**
   * Loadd all parameters
   */
  public async loadAll(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<ProcessParameter[]>(
          `${this.requestScheme}${environment.backendUrl}/parameters`,
          {
            observe: 'response',
          },
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._parameterStore$.upsertMany(response.body)
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Update cost of the material with the given ID
   */
  public updateMaterial(id: number, cost?: number) {
    const payload = {
      cost: cost ?? undefined,
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<ProcessParameter>(
          `${this.requestScheme}${environment.backendUrl}/parameters/materials/${id}`,
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._parameterStore$.upsert(response.body)
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Update a single element of a solid process parameter
   */
  public updateMaterialConcentrations(
    solidId: number,
    elementId: number,
    value: number
  ) {
    const payload = {
      Value: this.unitService.convertToFraction(value),
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<SolidParameterConcentration[]>(
          `${this.requestScheme}${environment.backendUrl}/parameters/materials/${solidId}/concentrations/${elementId}`,
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const additive = this._parameterStore$.getEditableItem(solidId);

            additive.Concentrations = response.body;

            this._parameterStore$.upsert(additive);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Save current material concentrations as default
   */
  public saveMaterialConcentrations(solidId: number) {
    const payload = [solidId];

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<SolidParameterConcentration[]>(
          `${this.requestScheme}${environment.backendUrl}/parameters/materials/${solidId}/concentrations/save`,
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this.loadAll();

            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Reset the process param concentration to the stored default.
   */
  public resetMaterialConcentrations(solidId: number) {
    const payload = [solidId];

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<SolidParameterConcentration[]>(
          `${this.requestScheme}${environment.backendUrl}/parameters/materials/concentrations/reset`,
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this.loadAll();

            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Get default process parameter values
   */
  public getDefaultParameters(): Promise<DefaultParameterResponse> {
    return new Promise<DefaultParameterResponse>((resolve, reject) => {
      this.http
        .get<DefaultParameterResponse>(
          `${this.requestScheme}${environment.backendUrl}/parameters/default_amounts`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            resolve(response.body);
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Update default process parameter values
   */
  public updateDefaultParameters(
    parameters: DefaultParameterUpdate[]
  ): Promise<DefaultParameterResponse> {
    const convertedParameters = parameters.map((i) =>
      this.conversionService.convertProcessParameterUnits(i, "toSi")
    );

    return new Promise<DefaultParameterResponse>((resolve, reject) => {
      this.http
        .put<DefaultParameterResponse>(
          `${this.requestScheme}${environment.backendUrl}/parameters/default_amounts`,
          convertedParameters,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            resolve(response.body);
          } else {
            reject();
          }
        });
    });
  }

  /* TEMPLATES */

  public get currentTemplates(): ParameterTemplate[] {
    return this.utilService.cloneObject(
      this._templates$.value.map((i) =>
        this.conversionService.toParameterTemplate(i)
      )
    );
  }

  public findTemplate(templateId: number): ParameterTemplate {
    return this._templates$.value.find((i) => i.Id === templateId);
  }

  public loadAllTemplates() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<ParameterTemplate[]>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._templates$.next(response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  public update(template: Partial<ParameterTemplateUpdate>) {
    const payload: Partial<ParameterTemplateUpdate> = {
      ...template,
      Parameters: template.Parameters.map((i) => ({
        ...i,
        ...this.conversionService.convertProcessParameterUnits(i, "toSi"),
      })),
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<ParameterTemplate>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios/${template.Id}`,
          // For simplicity I convert to fraction here. Normally I would do that in the unit service,
          // But for that I would need to pass the whole scenario to this function and thats dumb.
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const oldTemplates = this._templates$.value.filter(
              (i) => i.Id !== template.Id
            );

            this._templates$.next([...oldTemplates, response.body]);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  public async create(name: string) {
    const defaults = await this.getDefaultParameters();

    const payload: ParameterTemplateCreate = {
      Name: name,
      Parameters: [
        ...defaults.Bucket.map((i) => ({
          MaterialId: i.Id,
          Source: i.Source,
          Amount: i.Amount,
          Unit: i.Unit,
        })),
        ...defaults.Feeding.map((i) => ({
          MaterialId: i.Id,
          Source: i.Source,
          Amount: i.Amount,
          Unit: i.Unit,
        })),
        ...defaults.Burner.map((i) => ({
          MaterialId: i.Id,
          Source: i.Source,
          Amount: i.Amount,
          Unit: i.Unit,
        })),
      ],
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .post<ParameterTemplate>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios`,
          // For simplicity I convert to fraction here. Normally I would do that in the unit service,
          // But for that I would need to pass the whole scenario to this function and thats dumb.
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 201) {
            this._templates$.next([...this._templates$.value, response.body]);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  public delete(templateId: number) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<ParameterTemplate>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios/${templateId}`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this._templates$.next(
              this._templates$.value.filter((i) => i.Id !== templateId)
            );
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  public assign(templateId: number, heatIds: number[], sequenceId: number) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<void>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios/${templateId}/assign`,
          {
            HeatIds: heatIds,
          },
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this.sequencesService.updateHeatTemplate(
              "parameters",
              heatIds,
              templateId,
              sequenceId
            );
          } else {
            reject();
          }
        });
    });
  }

  public unassign(heatIds: number[], sequenceId: number) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<number[]>(
          `${this.requestScheme}${environment.backendUrl}/parameter_scenarios/unassign`,
          {
            Heats: heatIds,
          },
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this.sequencesService.onParameterTemplateUnassign(
              heatIds,
              sequenceId
            );
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}
