<div class="mock-table" [style.--numColumns]="nColumns">
  <div class="mock-table-header">
    <div class="mock-table-header-column" *ngFor="let column of columns">
      <div class="mock-table-item"></div>
    </div>
  </div>
  <div class="mock-table-body">
    <div class="mock-table-row" *ngFor="let row of rows">
      <div class="mock-table-column" *ngFor="let column of columns">
        <div class="mock-table-item"></div>
      </div>
    </div>
  </div>
</div>
