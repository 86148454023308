import { Component, OnInit } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-skeleton-heading",
  templateUrl: "./heading.component.html",
  styleUrls: ["./heading.component.scss"],
})
export class SkeletonHeadingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
