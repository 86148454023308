<div class="footer">
  <div
    class="footer-taskbar"
    [class.taskbarHidden]="(authService.authStatus | async) === false"
  >
    <span>
      {{
        lastDatabaseUpdateDate
          ? "Last database update on " + lastDatabaseUpdateDate
          : "Database was never updated"
      }}
    </span>
    (<a (click)="openReloadDataModal()">refresh</a>)
    <sms-help style="margin-left: 5px" placement="top">
      <p>Below is the status of database tables</p>
      <div class="footer-database-update">
        <div
          *ngFor="let updatedTable of tableList"
          class="footer-database-update-item"
        >
          <div class="footer-database-update-name">
            {{ updatedTable.TableName }}
          </div>
          <div class="footer-database-update-status">
            <fa-icon
              *ngIf="updatedTable.Status === true"
              [icon]="faCheckCircle"
            ></fa-icon>
            <fa-icon
              *ngIf="updatedTable.Status === false"
              [icon]="faTimesCircle"
            ></fa-icon>
            <fa-icon
              *ngIf="updatedTable.Status === null"
              [icon]="faMinusCircle"
            ></fa-icon>
          </div>
        </div>
      </div>
    </sms-help>
  </div>
  <div class="footer-main">
    <a
      href="https://my.sms-group.com/privacy-policy/"
      target="_blank"
      rel="noopener"
      >Privacy Policy</a
    >
    <a href="https://my.sms-group.com/imprint/" target="_blank" rel="noopener"
      >Imprint</a
    >
  </div>
</div>

<sms-modal
  id="reloadDataModal"
  title="Reload data?"
  type="content"
  size="small"
  (onClose)="closeReloadDataModal()"
>
  <p modal-content style="padding: 20px">
    Are you sure you want to reload all data? This can take up to 10 minutes.
  </p>
  <div modal-footer>
    <sms-button type="default" (click)="closeReloadDataModal()">
      Cancel
    </sms-button>
    <sms-button type="info" (click)="updateDatabase()">
      Reload data
    </sms-button>
  </div>
</sms-modal>
<sms-modal
  type="loader"
  id="dataReloadRunningModal"
  title="Reloading data..."
></sms-modal>
