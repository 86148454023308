import { Component } from "@angular/core";

import { faFrown } from "@fortawesome/pro-regular-svg-icons";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent {
  public faFrown = faFrown;
}
