import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ContentSwitchComponent } from "../content-switch.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit {
  public active: boolean = false;

  @HostBinding("style.height") private get isActive() {
    return this.active ? "100%" : "0";
  }
  /**
   * The text displayed in the Tab header
   */
  @Input() name: string | number;

  /**
   * String or number used to uniquely identify the tab. It is YOUR responsibility to guarantee the uniqueness
   */
  @Input() id: string | number;

  @Input() disabled?: boolean = false;
  @Input() title?: string = null;

  /**
   * Emits the id of the clicked tab when a user clicked on the tab header
   */
  @Output() onClick?: EventEmitter<any> = new EventEmitter();

  constructor(private contents: ContentSwitchComponent) {}

  ngOnInit() {
    this.contents.addContent(this);
  }
}
