import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  HostBinding,
  OnDestroy,
} from "@angular/core";

import { faTimes, faSpinner } from "@fortawesome/pro-regular-svg-icons";

import { ModalService } from "src/app/services/modal.service";

/**
 * A component to show modals. Used with the ModalService for controlling modals.
 * for onCancel and onOk functions, pass methods that control the modalService to close the modal.
 * ```
 *  <Modal
 *    id="myUniqueModal"
 *     title="Edit something"
 *     type="content"
 *     [showCancelButton]="true"
 *     (onOk)="onModalOk()"
 *   >
 *   ...content...
 *   </Modal>
 *
 *   // Where the onModalOk function could look like this:
 *
 *   public onModalOk() {
 *     doSomeWorkOnOk();
 *
 *     this.modalService.close("myUniqueModal");
 *   }
 * ```
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() title!: string;
  @Input() type?: "loader" | "content" = "content"; // Modal can be a fullscreen loader or contain content

  @Input() size?: "small" | "default" | "large" = "default";

  @Input() closable?: boolean = true;

  @Input() overflow?: boolean = true;

  @Input() height?: string = null;
  @Input() width?: string = null;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  private element: any;

  public fadeOut: boolean = false;

  public faTimes = faTimes;
  public faSpinner = faSpinner;

  @HostBinding("class.visible") public isOpen: boolean = false;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error("modal must have an id");
      return;
    }

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);

    if (!("remove" in Element.prototype)) {
      // IE11 fallback
      this.element.parentNode.removeChild(this.element);
    } else {
      this.element.remove();
    }
  }

  // open modal
  open(): void {
    this.fadeOut = false;
    this.isOpen = true;

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);
    this.element.style.display = "block";
    document.body.classList.add("modal-open");
  }

  // close modal
  close(): void {
    this.fadeOut = true;

    setTimeout(() => {
      this.isOpen = false;

      this.element.style.display = "none";
      document.body.classList.remove("modal-open");

      if (document.body.contains(this.element)) {
        document.body.removeChild(this.element);
      }
    }, 150);
  }
}
