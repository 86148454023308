import { Injectable } from "@angular/core";

import { unparse } from "papaparse";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  // Migrated from AngularJS 
  // https://raw.githubusercontent.com/Ins87/angular-date-interceptor/master/src/angular-date-interceptor.js
  public iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  constructor() {}

  /**
   * Clones the passed object if all types are supported,
   * Throws an error if it contains unsupported types.
   */
  public cloneObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * This method compares two objects for strict equality.
   */
  public compareObjects(obj1: object, obj2: object) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  /**
   * Generates a rfc4122 compliant UUID v4 (random)
   */
  public uuidv4(): string {
    const buf = new Uint32Array(4);
    window.crypto.getRandomValues(buf);
    let idx = -1;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      idx++;
      // tslint:disable-next-line:no-bitwise
      const r = (buf[idx >> 3] >> ((idx % 8) * 4)) & 15;
      // tslint:disable-next-line:no-bitwise
      const v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public convertToDate(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== "object") {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (this.isIso8601(value)) {
        body[key] = new Date(value);
      } else if (typeof value === "object") {
        body[key] = this.convertToDate(value);
      }
    }

    return body;
  }

  public isIso8601(value) {
    if (value === null || value === undefined) {
      return false;
    }

    return this.iso8601.test(value);
  }

  /**
   * Downloads a file to the users browser. 
   * Data should be a JSON string that can be converted into a CSV file.
   * There is NO mechanism that tests if the file conversion was a success, 
   * so when using this method it should be tested a lot!
   */
  public downloadJsonAsCSV(data, filename: string, columns: string[] = null) {
    const csv = unparse({ data, fields: columns });
    if (csv == null) return;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename); 
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
    } else {
      const a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click(); 
      // IE: "Access is denied"; see: 
      // https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      document.body.removeChild(a);
    }
  }
}
