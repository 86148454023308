import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import {
  faSignOut,
  faCog,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";

import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";
import {
  PreferredWeightUnit,
  UnitService,
} from "src/app/services/unit.service";
import { ModalService } from "src/app/services/modal.service";
import { ChangelogService } from "src/app/services/api/changelog.service";
import { ElementsService } from "src/app/services/api/elements.service";

/**
 * Main page header
 */
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  public sidebarVisible: boolean = false;

  public selectedWeightUnit: PreferredWeightUnit = "st";
  public availableUnits = [
    { id: "st", value: "Short tons" },
    { id: "gt", value: "Gross tons" },
    { id: "mt", value: "Metric tons" },
  ];

  public isLoggedIn: boolean = false;

  public environmentType = environment.type;

  public faSignOut = faSignOut;
  public faCog = faCog;
  public faQuestionCircle = faQuestionCircle;

  constructor(
    private authService: AuthService,
    private unitService: UnitService,
    private modalService: ModalService,
    private router: Router,
    public changelogService: ChangelogService,
    public elementsService: ElementsService
  ) {
    this.router.events.subscribe((next) => {
      if (next instanceof NavigationEnd) {
        this.closeSidebar();
      }
    });

    this.unitService.preferredWeightUnit.subscribe((next) => {
      this.selectedWeightUnit = next; // if short tons are selected, the switch will reflect that.
    });

    this.authService.authStatus.subscribe((next) => {
      this.isLoggedIn = next;
    });
  }

  public switchPreferedWeightUnit(units: PreferredWeightUnit[]) {
    this.unitService.setPreferedWeightUnit(units[0]);
  }

  public logout() {
    this.authService.logout();
    this.closeLogoutModal();
  }

  public toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  public openSidebar() {
    this.sidebarVisible = true;
  }

  public closeSidebar() {
    this.sidebarVisible = false;
  }

  /*
    Modal functions
  */

  public openLogoutModal() {
    this.modalService.open("logoutModal");
  }

  public closeLogoutModal() {
    this.modalService.close("logoutModal");
  }

  public openChangelogModal() {
    this.modalService.open("changelogModal");
  }

  public closeChangelogModal() {
    this.modalService.close("changelogModal");
  }
}
