import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ContentComponent } from "./content/content.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-content-switch",
  templateUrl: "./content-switch.component.html",
  styleUrls: ["./content-switch.component.scss"],
})
export class ContentSwitchComponent {
  @Input() public title: string = null;

  @Output() public onContentSwitch: EventEmitter<
    string | number
  > = new EventEmitter();

  public contents: ContentComponent[] = [];

  public addContent(content: ContentComponent) {
    const activeContents = this.contents.filter((i) => i.active);

    if (activeContents.length === 0) {
      if (!content.disabled) {
        content.active = true;
        this.onContentSwitch.emit(content.id);
      }
    }

    this.contents.push(content);
  }

  public selectContent(content: ContentComponent) {
    // Resize event triggered to redraw plots
    // this is needed because plotly does not recognize the tab-switch and will not resize the plot to the full with
    // until the resize event is fired.
    let event;
    if (typeof Event === "function") {
      event = new Event("resize");
    } else {
      event = document.createEvent("Event");
      event.initEvent("resize", true, true);
    }

    window.dispatchEvent(event);

    this.contents.forEach((t) => {
      t.active = false;
    });
    content.active = true;

    // Trigger the "onclick" event of the tab component.
    content.onClick.emit(content.id);
    this.onContentSwitch.emit(content.id);
  }
}
