import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { Element, ElementSetting } from "src/app/types/element.model";
import { environment } from "src/environments/environment";
import { DataStore } from "src/app/types/data-store.model";
import { UnitService } from "../unit.service";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { ConversionService } from "../conversion.service";

class ElementStore extends DataStore<Element> {}

@Injectable({
  providedIn: "root",
})
export class ElementsService {
  private _elementStore$: ElementStore = new ElementStore();
  private settings$: BehaviorSubject<ElementSetting[]> = new BehaviorSubject(
    []
  );

  /**
   * Synchronises all elements with the backend
   */
  public readonly elements: Observable<
    Element[]
  > = this._elementStore$.getAllObservable();

  public readonly settings: Observable<ElementSetting[]> = combineLatest([
    this.settings$.asObservable(),
    this.unitService.preferredWeightUnit,
  ]).pipe(
    map(([settings, unit]) =>
      settings.map((j) => this.conversionService.toElementSetting(j))
    ),
    distinctUntilChanged(),
    shareReplay()
  );

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(
    private http: HttpClient,
    private unitService: UnitService,
    private conversionService: ConversionService
  ) {}

  /**
   * Returns all currently registered elements
   */
  public get currentElements(): Element[] {
    return this._elementStore$.getAll();
  }

  /**
   * Gets all current elements
   */
  public async loadAll() {
    this.http
      .get<Element[]>(
        `${this.requestScheme}${environment.backendUrl}/elements`,
        {
          observe: "response",
        }
      )
      .subscribe((response) => {
        if (response.status === 200) {
          this._elementStore$.upsertMany(response.body);
        } else {
          this._elementStore$.next(new Map());
        }
      });

    await this.loadAllSettings();
  }

  /**
   * Find a single element, that was already fetched, by ID.
   * Will return the "unknown" element if ID === 0, null if it cannot find anything.
   */
  public find(id: number): Element {
    if (id === 0) {
      // Return the "unknown" element if an element with Id 0 was requested.
      return {
        Id: 0,
        Symbol: "Unknown",
        Commodities: false,
        Additives: false,
        ProductionTargets: false,
      };
    } else {
      return this._elementStore$.get(id);
    }
  }

  /**
   * Find an already fetched element by it's symbol/name
   */
  public getElementBySymbol(symbol: string): Element {
    return this._elementStore$
      .getAll()
      .find((element) => element.Symbol === symbol);
  }

  /**
   * Load element settings ("Should be considered by the optimizer" and safety margin settings)
   */
  public async loadAllSettings() {
    this.http
      .get<ElementSetting[]>(
        `${this.requestScheme}${environment.backendUrl}/elements/settings`,
        {
          observe: "response",
        }
      )
      .subscribe((response) => {
        if (response.status === 200) {
          this.settings$.next(response.body);
        } else {
          this.settings$.next([]);
        }
      });
  }

  /**
   * Update element setting for one element
   */
  public updateSetting(elementId: number, consider?: boolean, margin?: number) {
    const body: Partial<ElementSetting> = {};

    if (consider != null) {
      body.Consider = consider;
    }

    if (margin != null) {
      body.Margin = this.unitService.convertToFraction(margin);
    }

    this.http
      .put<ElementSetting>(
        `${this.requestScheme}${environment.backendUrl}/elements/${elementId}/settings`,
        body,
        {
          observe: "response",
        }
      )
      .subscribe((response) => {
        if (response.status === 200) {
          const newSettings = [
            ...this.settings$.value.filter((i) => i.ElementId !== elementId),
            response.body,
          ];

          this.settings$.next(newSettings);
        } else {
          this.settings$.next([]);
        }
      });
  }
}
