<div class="header">
  <div class="header-info">
    <img
      src="../../../assets/images/sdig_white.svg"
      alt="SMS Group"
      class="header-info-logo"
      routerLink="/"
    />
    <h2 routerLink="/" class="header-info-title">Metallics Optimizer</h2>
    <div class="header-info-tags">
      <span
        class="header-info-tag header-info-tag-environment"
        *ngIf="environmentType !== 'prod'"
      >
        {{ environmentType }}
      </span>
    </div>
  </div>
  <div class="header-nav" *ngIf="isLoggedIn">
    <ul class="header-nav-items">
      <li
        class="header-nav-item"
        routerLinkActive="header-nav-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          class="header-nav-item-title"
          id="sidebar-link-home"
          [routerLink]="'/'"
        >
          Dashboard
        </a>
      </li>

      <li class="header-nav-item" routerLinkActive="header-nav-item-active">
        <a
          class="header-nav-item-title"
          id="sidebar-link-commodities"
          [routerLink]="'/commodities'"
        >
          Commodities
        </a>
      </li>

      <li class="header-nav-item" routerLinkActive="header-nav-item-active">
        <a
          class="header-nav-item-title"
          id="sidebar-link-grades"
          [routerLink]="'/grades'"
        >
          Grades
        </a>
      </li>

      <li class="header-nav-item" routerLinkActive="header-nav-item-active">
        <a
          class="header-nav-item-title"
          id="sidebar-link-sequences"
          [routerLink]="'/sequences/edit'"
        >
          Sequences
        </a>
      </li>

      <li class="header-nav-item" routerLinkActive="header-nav-item-active">
        <a
          class="header-nav-item-title"
          id="sidebar-link-sequences"
          [routerLink]="'/sequences/optimizations'"
        >
          Results
        </a>
      </li>
    </ul>
  </div>

  <div class="header-actions" *ngIf="isLoggedIn">
    <div
      class="header-action"
      [class.header-action-active]="sidebarVisible"
      (click)="toggleSidebar()"
    >
      <fa-icon [icon]="faCog"></fa-icon>
    </div>
    <div class="header-action" (click)="openChangelogModal()">
      <fa-icon [icon]="faQuestionCircle"></fa-icon>
    </div>
    <div
      class="header-action"
      (click)="openLogoutModal()"
      style="padding: 0 10px"
    >
      <span>Logout</span>
      <fa-icon [icon]="faSignOut"></fa-icon>
    </div>
  </div>
</div>

<div
  class="header-sidebar-overlay"
  *ngIf="sidebarVisible"
  (click)="closeSidebar()"
></div>

<div class="header-sidebar" *ngIf="sidebarVisible">
  <ul>
    <li>
      <label class="title">Preferred weight unit</label>
      <sms-select
        [items]="availableUnits"
        [(value)]="selectedWeightUnit"
        (valueChange)="switchPreferedWeightUnit($event)"
        [multiple]="false"
      ></sms-select>
    </li>
    <li class="spacer"></li>
    <li>
      <a [routerLink]="'/settings/general'">General Settings</a>
    </li>
    <li>
      <a [routerLink]="'/settings/templates'">Templates</a>
    </li>
    <li>
      <a [routerLink]="'/settings/optimization'">Optimization Settings</a>
    </li>
    <li>
      <a [routerLink]="'/settings/processparameters'">Process Parameters</a>
    </li>
  </ul>
</div>

<sms-modal
  id="changelogModal"
  title="Changelog"
  type="content"
  (onClose)="closeChangelogModal()"
  size="large"
>
  <sms-tabs modal-content>
    <sms-tab name="User interface">
      <div style="padding: 16px">
        <markdown src="assets/CHANGELOG.md"></markdown>
      </div>
    </sms-tab>
    <sms-tab name="Optimizers and API">
      <div style="padding: 16px">
        <markdown [data]="this.changelogService.backendChangelog"></markdown>
      </div>
    </sms-tab>
  </sms-tabs>

  <div modal-footer>
    <sms-button type="default" (click)="closeChangelogModal()"> OK </sms-button>
  </div>
</sms-modal>

<sms-modal
  id="logoutModal"
  title="Logout"
  type="content"
  (onClose)="closeLogoutModal()"
  size="small"
>
  <div modal-content style="padding: 16px">
    Are you sure you want to sign out?
  </div>
  <div modal-footer>
    <sms-button type="default" (click)="closeLogoutModal()">
      Cancel
    </sms-button>
    <sms-button type="info" (click)="logout()"> Logout </sms-button>
  </div>
</sms-modal>
