import { Component, Input, OnInit } from "@angular/core";

import { faCircle, faDotCircle } from "@fortawesome/pro-regular-svg-icons";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
})
export class RadioComponent implements OnInit {
  @Input() public enabled: boolean = false;

  public faCircle = faCircle;
  public faDotCircle = faDotCircle;

  constructor() {}

  ngOnInit(): void {}
}
