<div class="modal" title="" *ngIf="type === 'content' && isOpen; else loading">
  <div
    (click)="
      closable
        ? onClose.observers.length > 0
          ? onClose.emit()
          : close()
        : null
    "
    [ngClass]="{
      'modal-background': true,
      'modal-background-fadeIn': !fadeOut,
      'modal-background-fadeOut': fadeOut
    }"
  ></div>
  <div
    [class]="['modal-body', 'modal-body-size-' + size]"
    [style.overflow]="overflow ? 'scroll' : 'hidden'"
    [style.height]="height"
    [style.width]="width"
  >
    <div class="modal-head">
      <div class="modal-title">
        {{ title }}
      </div>
      <div
        class="modal-close"
        (click)="
          closable
            ? onClose.observers.length === 0
              ? close()
              : onClose.emit()
            : null
        "
        *ngIf="closable"
      >
        <fa-icon [icon]="faTimes"></fa-icon>
      </div>
    </div>
    <div class="modal-content">
      <ng-content select="[modal-content]"></ng-content>
    </div>
    <div class="modal-footer">
      <ng-content select="[modal-footer]"></ng-content>
    </div>
  </div>
</div>

<ng-template #loading>
  <ng-container *ngIf="isOpen">
    <div class="modal modal-loader" title="">
      <sms-loader></sms-loader>
      <h2>{{ title }}</h2>
    </div>
    <div
      class="modal-background"
      [ngClass]="{
        'modal-background': true,
        'modal-background-fadeIn': !fadeOut,
        'modal-background-fadeOut': fadeOut
      }"
    ></div>
  </ng-container>
</ng-template>
