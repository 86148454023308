import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LayoutService } from "src/app/services/layout.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-split-view",
  templateUrl: "./split-view.component.html",
  styleUrls: ["./split-view.component.scss"],
})
export class SplitViewComponent implements OnInit {
  @Input() public vertical: boolean = false;

  @Input("left") set initialLeft(value: number) {
    this.left = value;
  }

  @Input("right") set initialRight(value: number) {
    this.right = value;
  }

  @Input() forceResizeEvent: boolean = false;

  @Input() minRight = 20;
  @Input() minLeft = 20;

  @ViewChild("splitViewContainer") splitViewContainer: ElementRef;

  public left: number;
  public right: number;

  private dragging: boolean = false;
  private dragStartPosition: number;
  private initalWidths: { right: number; left: number };

  constructor(private layoutService: LayoutService) {
    this.left = 50;
    this.right = 50;
    this.initalWidths = { right: 50, left: 50 };
  }

  ngOnInit() {}

  public onGrab(event: MouseEvent) {
    this.dragging = true;

    if (this.vertical) {
      this.dragStartPosition = event.clientY;
    } else {
      this.dragStartPosition = event.clientX;
    }

    this.initalWidths = { right: this.right, left: this.left };

    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("document:mouseup", ["$event"])
  public onGrabEnd(event: MouseEvent) {
    this.dragging = false;
  }

  @HostListener("document:mousemove", ["$event"])
  public grabberMove(event: MouseEvent) {
    if (this.dragging) {
      let percentage: number = null;
      let offset: number = null;

      const container = this.splitViewContainer.nativeElement as HTMLDivElement;

      if (this.vertical) {
        offset = event.clientY - this.dragStartPosition;
        percentage = (offset / container.clientHeight) * 100;
      } else {
        offset = event.clientX - this.dragStartPosition;
        percentage = (offset / container.clientWidth) * 100;
      }

      const newRight = this.initalWidths.right - percentage;
      const newLeft = this.initalWidths.left - -percentage;

      if (percentage >= 0) {
        if (newRight < this.minRight) {
          this.right = this.minRight;
          this.left = 100 - this.minRight;
        } else {
          this.right = newRight;
          this.left = newLeft;
        }
      } else {
        if (newLeft < this.minLeft) {
          this.left = this.minLeft;
          this.right = 100 - this.minLeft;
        } else {
          this.right = newRight;
          this.left = newLeft;
        }
      }

      if (this.forceResizeEvent) {
        this.layoutService.triggerResizeEvent();
      }
    }
  }
}
