import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { CopperEfficiencyItem } from "src/app/types/copper-efficiency.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CopperEfficiencyService {
  private _copperEfficiency: BehaviorSubject<
    CopperEfficiencyItem[]
  > = new BehaviorSubject([]);

  /**
   * Get copper efficiency data as an observable. It will update every 15 minutes.
   */
  public readonly copperEfficiency: Observable<
    CopperEfficiencyItem[]
  > = this._copperEfficiency.pipe(distinctUntilChanged(), shareReplay());

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  public oldestDate: Observable<Date> = this.http
    .get<string>(
      `${this.requestScheme}${environment.backendUrl}/cu_efficiency/oldest`,
      { observe: "body" }
    )
    .pipe(map((res) => (res ? new Date(res) : null)));

  constructor(private http: HttpClient) {}

  /**
   * Loads all available copper efficiency items.
   * Remember that those come directly out of the datalake, so no
   * unit conversion is needed.
   */
  public load() {
    let promise = new Promise<void>((resolve, reject) => {
      this.http
        .get<CopperEfficiencyItem[]>(
          `${this.requestScheme}${environment.backendUrl}/cu_efficiency`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._copperEfficiency.next(response.body);
            resolve();
          } else {
            this._copperEfficiency.next([]);
            reject();
          }
        });
    });

    return promise;
  }
}
