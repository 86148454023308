<div class="content-switch-container">
  <div class="content-switch-header">
    <div class="content-switch-header-title">
      {{ title }}
    </div>
    <div class="content-switch-header-items">
      <div
        *ngFor="let content of contents"
        [ngClass]="{
          'content-switch-header-item': true,
          'content-switch-header-item-active': content.active,
          'content-switch-header-item-disabled': content.disabled
        }"
        [title]="content.title ? content.title : ''"
        (click)="content.disabled ? null : selectContent(content)"
      >
        {{ content.name }}
      </div>
    </div>
  </div>
  <div class="content-switch-body">
    <ng-content></ng-content>
  </div>
</div>
