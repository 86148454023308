<div class="tab-container">
  <div class="tab-header">
    <div
      *ngFor="let tab of tabs"
      [ngClass]="{
        'tab-header-item': true,
        'tab-header-item-active': tab.active,
        'tab-header-item-disabled': tab.disabled
      }"
      [title]="tab.title ? tab.title : ''"
      (click)="tab.disabled ? null : selectTab(tab)"
    >
      {{ tab.name }}
    </div>
  </div>
  <div class="tab-body">
    <ng-content></ng-content>
  </div>
</div>
