import { Injectable } from "@angular/core";
import { take, takeWhile, tap } from "rxjs/operators";
import { AuthService } from "../auth.service";
import { LayoutService } from "../layout.service";
import { ChangelogService } from "./changelog.service";
import { CommoditiesService } from "./commodities.service";
import { CopperEfficiencyService } from "./copper-efficiency.service";
import { ElementsService } from "./elements.service";
import { GradesService } from "./grades.service";
import { ProcessParametersService } from "./process-parameters.service";
import { SequencesService } from "./sequences.service";
import { ConstraintTemplatesService } from "./constraint-templates.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private authService: AuthService,
    private commoditiesService: CommoditiesService,
    private gradesService: GradesService,
    private sequencesService: SequencesService,
    private elementsService: ElementsService,
    private changelogService: ChangelogService,
    private constraintTemplatesService: ConstraintTemplatesService,
    private processParametersService: ProcessParametersService,
    private copperEfficiencyService: CopperEfficiencyService,
    public layoutService: LayoutService
  ) {}

  public loadAllIfAuthenticated(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.authService.authStatus
        .pipe(takeWhile((state) => state !== true, true))
        .subscribe((status) => {
          if (status) {
            this.layoutService.setShowPageLoader(true);

            this.loadAll().then((res) => {
              this.layoutService.setShowPageLoader(false);
              resolve();
            });
          }
        });
    });
  }

  /**
   * Loads all backend data needed for launching the app.
   * It also registers a 15 minute interval for re-loading copper eff. data.
   */
  public loadAll(): Promise<void> {
    setInterval(() => {
      this.copperEfficiencyService.load();
    }, 900000); // A 15 minute interval to reload the data once in a while

    // Load data needed for running the app (except stocks at the moment)
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.elementsService.loadAll(),

        this.commoditiesService.loadAll(),
        this.commoditiesService.loadAllConstraints(),
        // this.commoditiesService.loadAllPredictionStatus(), // Not used at the moment

        this.gradesService.loadAll(),
        this.sequencesService.loadAll(),

        // changelog
        this.changelogService.getBackendData(),

        // process parameters
        this.processParametersService.loadAll(),

        // templates
        this.processParametersService.loadAllTemplates(),
        this.constraintTemplatesService.loadAll(),
      ])
        .then((values) => {
          // This will wait till all data is loaded, then the page loader is removed.
          this.layoutService.setShowPageLoader(false);
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  }
}
