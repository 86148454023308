<div class="select" *ngIf="multiple; else singleSelect">
  <select [id]="selectId" [multiple]="true"></select>
  <div class="select-value">
    <label [for]="selectId">
      <span
        class="select-value-tag"
        *ngFor="
          let item of selectedItems ? selectedItems.slice(0, 2) : [];
          let i = index
        "
        [title]="item.value"
      >
        {{ item.value }}
      </span>
      <span
        class="select-value-tag"
        *ngIf="selectedItems.length > 2"
        [title]="selectedItems.length - 2 + ' more items selected'"
      >
        ...
      </span>
      <fa-icon [icon]="faChevronDown"> </fa-icon>
    </label>
  </div>
  <div class="select-options">
    <div
      *ngFor="let i of itemList"
      class="select-option"
      [class.selected]="i.selected"
      (click)="onValueClick(i.item, false)"
      tabindex="0"
    >
      <label
        class="checkbox-label"
        *ngIf="multiple"
        (click)="onValueClick(i.item, true)"
      >
        <input type="checkbox" lang="en" [checked]="i.selected" />
        <span class="checkbox-custom"></span>
      </label>
      <span>{{ i.item.value }}</span>
    </div>
  </div>
</div>

<ng-template #singleSelect>
  <div class="select">
    <select [id]="selectId" [multiple]="false"></select>
    <div class="select-value">
      <label [for]="selectId">
        <span [title]="selectedItems[0].value">
          {{ selectedItems[0].value }}
        </span>
        <fa-icon [icon]="faChevronDown"> </fa-icon>
      </label>
    </div>
    <div class="select-options">
      <div
        *ngFor="let i of itemList"
        class="select-option"
        [class.selected]="i.selected"
        (click)="onValueClick(i.item, false)"
        tabindex="0"
      >
        <span class="single">{{ i.item.value }}</span>
      </div>
    </div>
  </div>
</ng-template>
