<div
  class="split-view"
  [class.split-view-vertical]="vertical"
  [style.--left]="left + '%'"
  [style.--right]="right + '%'"
  #splitViewContainer
>
  <div class="split-view-left">
    <ng-content select="[left]"></ng-content>
  </div>
  <div class="split-view-divider" (mousedown)="onGrab($event)"></div>
  <div class="split-view-right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
