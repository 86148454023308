import { Injectable, RendererFactory2, OnDestroy } from "@angular/core";
import { UtilService } from "./util.service";

interface Callbacks {
  onMouseMove: (e: MouseEvent) => void;
  onMouseUp: (e: MouseEvent) => void;
}

/**
 * This service is used as an performance-boost for sliders within the app. 
 * Because they all use a similar "onMouseMove" event,
 * It makes sense to keep track of such events in a single place, 
 * this way only one event handler is needed, thus increasing performance a lot.
 * 
 * All this service does is keeping track of "active" sliders (so sliders currently in use) 
 * and only registering event handlers for those.
 * When registering a slider, it takes callback functions for mouse events.
 */
@Injectable({
  providedIn: "root",
})
export class SliderService implements OnDestroy {
  private _sliders: Map<string, Callbacks> = new Map();

  private _activeSlider: string = null;

  private removeMouseMoveListener: () => void;
  private removeMouseUpListener: () => void;

  constructor(
    private utilService: UtilService,
    private rendererFactory2: RendererFactory2
  ) {
    const renderer = this.rendererFactory2.createRenderer(null, null);

    this.removeMouseMoveListener = renderer.listen(
      "document",
      "mousemove",
      this.mouseMoveCallback.bind(this)
    );

    this.removeMouseUpListener = renderer.listen(
      "document",
      "mouseup",
      this.mouseUpCallback.bind(this)
    );
  }

  ngOnDestroy() {
    this.removeMouseMoveListener();
    this.removeMouseUpListener();
    this.rendererFactory2.end();
  }

  public get activeSlider(): string {
    return this._activeSlider;
  }

  public set activeSlider(id: string) {
    if (id === null) {
      this._activeSlider = null;
    } else if (this._sliders.has(id)) {
      this._activeSlider = id;
    } else {
      console.warn("A slider wit the id " + id + " does not exist.");
      this._activeSlider = null;
    }
  }

  public register(
    mouseMoveCallback: (e: MouseEvent) => void,
    mouseUpCallback: (e: MouseEvent) => void
  ): string {
    const id = this.utilService.uuidv4();

    const callbacks: Callbacks = {
      onMouseMove: mouseMoveCallback,
      onMouseUp: mouseUpCallback,
    };

    this._sliders.set(id, callbacks);

    return id;
  }

  public mouseMoveCallback(event: MouseEvent) {
    if (this.activeSlider) {
      const callbacks = this._sliders.get(this._activeSlider);

      if (callbacks.onMouseMove) {
        callbacks.onMouseMove(event);
      } else {
        console.error(
          "Could not call onMouseMove function for the active slider."
        );
      }
    }
  }

  public mouseUpCallback(event: MouseEvent) {
    if (this.activeSlider) {
      const callbacks = this._sliders.get(this._activeSlider);

      if (callbacks.onMouseUp) {
        callbacks.onMouseUp(event);
      } else {
        console.error(
          "Could not call onMouseUp function for the active slider."
        );
      }
    }
  }
}
