import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

import { LayoutService } from "./services/layout.service";
import { ApiService } from "./services/api/api.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    public layoutService: LayoutService
  ) {
    this.apiService.loadAllIfAuthenticated();
  }

  ngOnInit(): void {
    this.getRouteTitle().subscribe((title: string) => {
      this.titleService.setTitle(`${title} | Metallics Optimizer`);
    });
  }

  /**
   * Give observable of title of active route
   * @returns Observable<string>
   */
  private getRouteTitle(): Observable<string> {
    const appTitle = this.titleService.getTitle();

    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        const activatedRoute = this.getChildActivatedRoute(this.activatedRoute);
        if (activatedRoute.snapshot.data.title) {
          return activatedRoute.snapshot.data.title;
        }
        return appTitle;
      })
    );
  }

  /**
   * Get child activated route of any givan activated route
   * @param activatedRoute ActivatedRoute
   * @returns ActivatedRoute
   */
  private getChildActivatedRoute(
    activatedRoute: ActivatedRoute
  ): ActivatedRoute {
    let childActivatedRoute = activatedRoute.firstChild;
    while (childActivatedRoute.firstChild) {
      childActivatedRoute = childActivatedRoute.firstChild;
    }
    return childActivatedRoute;
  }
}
