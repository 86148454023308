import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  private _showPageLoader$: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );

  public readonly showPageLoader$: Observable<boolean> = this._showPageLoader$.asObservable();

  public readonly baseChartLayout = {
    plot_bgcolor: "transparent",
    paper_bgcolor: "transparent",
    title: {
      font: {
        color: "#F4F4F4",
      },
    },
    legend: {
      orientation: "h",
      font: {
        color: "#F4F4F4",
      },
    },
    barmode: "group",
    autosize: true,
    xaxis: {
      color: "#F4F4F4",
      gridcolor: "#393939",
    },
    yaxis: {
      color: "#F4F4F4",
      gridcolor: "#393939",
    },
    colorscale: {
      sequential: [
        "#0F62FE",
        "#4589FF",
        "#78A9FF",
        "#A6C8FF",
        "#D0E2FF",
        "#EDF5FF",
      ],
    },
    coloraxis: {
      autocolorscale: true,
      colorbar: {
        tickcolor: "#262626",
      },
    },
    colorway: [
      "#33B1FF",
      "#FA4D56",
      "#007D79",
      "#8A3FFD",
      "#FF7EB6",
      "#FFF1F1",
      "#6FDC8C",
      "#4589FF",
      "#D12771",
      "#D2A106",
      "#08BDBA",
      "#BAE6FF",
      "#BA4E00",
      "#BE95FF",
    ],
  };

  public triggerResizeEvent() {
    // Resize event triggered to redraw plots
    let event;
    if (typeof Event === "function") {
      event = new Event("resize");
    } else {
      event = document.createEvent("Event");
      event.initEvent("resize", true, true);
    }

    window.dispatchEvent(event);
  }

  public setShowPageLoader(state: boolean) {
    this._showPageLoader$.next(state);
  }
}
