import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BehaviorSubject, Observable } from "rxjs";

import * as moment from "moment";

import { environment } from "src/environments/environment";

import { SequencesService } from "./sequences.service";
import { CommoditiesService } from "./commodities.service";
import { GradesService } from "./grades.service";
import { ElementsService } from "./elements.service";
import { DatabaseStatus } from "src/app/types/database.model";
import { AuthService } from "../auth.service";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  private _databaseUpdate: BehaviorSubject<DatabaseStatus> = new BehaviorSubject(
    null
  );
  /**
   * Get the database status as observable
   */
  public readonly databaseUpdate: Observable<DatabaseStatus> = this._databaseUpdate.asObservable();

  public loggedIn: boolean = false;

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.authService.authStatus.subscribe((authStatus) => {
      // If the user was not previously logged in, but the new status sais he should be
      // We trigger an update of the database status. So when the user reloads the page
      // or is logged in for the first time the update takes place.

      if (!this.loggedIn && authStatus) {
        this.getStatus()
          .then((status) => {
            this._databaseUpdate.next(status);
          })
          .catch((err) => {
            console.error(
              "Could not fetch the database status, as no user is currently logged in."
            );
          });
      }

      this.loggedIn = authStatus;
    });
  }

  /**
   * Fetch the current DB status from BE
   */
  private getStatus(): Promise<DatabaseStatus> {
    return new Promise<DatabaseStatus>((resolve, reject) => {
      this.http
        .get(`${this.requestScheme}${environment.backendUrl}/database/status`)
        .toPromise()
        .then((res: any) => {
          const utcDateTime = moment.utc(res.UpdateDate);
          const localDateTime = utcDateTime.local().toDate();

          const status: DatabaseStatus = {
            Tables: res.Tables,
            UpdateDate: localDateTime,
          };

          resolve(status);
        })
        .catch(() => {
          reject(null);
        });
    });
  }

  /**
   * Update the "databaseService.databaseUpdate" status field.
   */
  public async update(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http
        .post(
          `${this.requestScheme}${environment.backendUrl}/database/update`,
          {}
        )
        .toPromise()
        .then((res: any) => {
          this.apiService.loadAll().then(() => {
            // This will wait till all data is loaded, then the page loader is removed.
            this.getStatus().then((status) => {
              this._databaseUpdate.next(status);

              resolve(true);
            });
          });
        })
        .catch(() => {
          reject(false);
        });
    });
  }
}
