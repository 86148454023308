<div class="slider-container">
  <input
    type="number"
    [(ngModel)]="valueMin"
    (keydown.enter)="minValueChanged($event)"
    (focusout)="minValueChanged($event)"
    [min]="min"
    [max]="valueMax || max"
    step="0.1"
  />
  <span class="label">0%</span>
  <div class="slider-body">
    <div class="slider" #slider>
      <div
        class="grabber"
        (mousedown)="onMinGrab($event)"
        [style.left]="'calc(' + left + '% - 3px)'"
        [style.z-index]="lastGrabbed === 'min' ? (grabbingMin ? 4 : 3) : 2"
      >
        <div class="slider-tooltip" *ngIf="grabbingMin">
          {{ sliderValueMin | number }}
        </div>
      </div>

      <div
        class="slider-inner"
        [style.left]="left + '%'"
        [style.right]="right + '%'"
        #sliderInner
      ></div>

      <div
        class="grabber"
        (mousedown)="onMaxGrab($event)"
        [style.right]="'calc(' + right + '% - 12px)'"
        [style.z-index]="lastGrabbed === 'max' ? (grabbingMax ? 4 : 3) : 2"
      >
        <div class="slider-tooltip" *ngIf="grabbingMax">
          {{ sliderValueMax | number }}
        </div>
      </div>
    </div>
    <div class="slider-area" [style]="{ left: 0, width: min + '%' }"></div>
    <div
      class="slider-area"
      [style]="{ right: '0%', width: 100 - max + '%' }"
    ></div>
  </div>
  <span class="label">100%</span>
  <input
    type="number"
    [(ngModel)]="valueMax"
    (keydown.enter)="maxValueChanged($event)"
    (focusout)="maxValueChanged($event)"
    [min]="valueMin || min"
    [max]="max"
    step="0.1"
  />
</div>
