import { Component, Input } from "@angular/core";

import {
  faQuestionCircle,
  faExclamationCircle,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";

/**
 * Displays a hoverable help badge that displays the passed text
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
})
export class HelpComponent {
  @Input() type: "help" | "warning" | "error" | "success" = "help";

  @Input() placement: "top" | "right" | "bottom" | "left" = "bottom";

  public get icon() {
    switch (this.type) {
      case "help":
        return faQuestionCircle;
      case "warning":
        return faExclamationCircle;
      case "error":
        return faTimesCircle;
      case "success":
        return faCheckCircle;
      default:
        return faQuestionCircle;
    }
  }
}
