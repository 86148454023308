import { Injector } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, distinctUntilChanged, shareReplay } from "rxjs/operators";
import { UtilService } from "../services/util.service";

const injector = Injector.create({
  providers: [{ provide: UtilService }],
});

export interface Storable {
  Id: number;
}

/**
 * This is an extension to RxJs' BehaviorSubject.
 * It allows to store data in an efficient and lightweight way while having the whole RxJs functionality.
 */
export class DataStore<T extends Storable> extends BehaviorSubject<
  Map<number, T>
> {
  private utilService: UtilService;

  constructor() {
    const initialData: Map<number, T> = new Map();

    // When emiting the initial data, the objects are frozen first to make them immutable
    super(deepFreeze(initialData));

    this.utilService = injector.get(UtilService);
  }

  public getEditableItem(id: number): T {
    const item = this.value.get(id) || null;
    return this.utilService.cloneObject(item);
  }

  /**
   * Updates an item if it exists, inserts it otherwise.
   */
  public upsert(item: T): boolean {
    if (item != null && typeof item === "object") {
      const newMap = new Map(this.value);
      newMap.set(item.Id, item);

      this.next(newMap);
      return newMap.has(item.Id);
    } else {
      return false;
    }
  }

  public upsertMany(items: T[]): boolean {
    let success = false;
    const newMap = new Map(this.value);

    for (const item of items) {
      newMap.set(item.Id, item);

      success = newMap.has(item.Id);

      if (!success) {
        return false;
      }
    }

    this.next(newMap);
    return true;
  }

  /**
   * ONLY USE IF YOU KNOW WHAT YOU DO.
   * This will directly override the store "Map".
   * If you want to persist it or just add/update items use the other methods.
   */
  public next(data: Map<number, T>): void {
    const frozenData = deepFreeze(data);
    const currentData = this.getValue();

    // When new data is emitted, it is frozen first to make it immuatble.
    // Then, using compareMaps, we check if there where changes.
    // If so, emit the new data to the BehaviorSubject.
    if (!compareMaps(frozenData, currentData)) {
      super.next(frozenData);
    }
  }

  /**
   * Returns all commodities with the right units
   */
  public getAllObservable(): Observable<T[]> {
    return this.asObservable().pipe(
      map((data) => [...data.values()]),
      distinctUntilChanged(),
      shareReplay()
    );
  }

  /**
   * Returns all commodities currently in the store, as a plain array (NOT as observable)
   */
  public getAll(): T[] {
    return [...this.value.values()];
  }

  /**
   * Returns the item with the given id if exists, null otherwise
   */
  public get(id: number): T {
    return this.value.get(id) || null;
  }

  public getObservable(id: number): Observable<T> {
    return this.asObservable().pipe(map((i) => i.get(id) || null));
  }

  public exists(id: number): boolean {
    return this.value.has(id);
  }
}

function nativeCompare(objOne, objTwo): boolean {
  // comparing with JSON.stringify is faster and more reliable then to do it with plain js.
  return JSON.stringify(objOne) === JSON.stringify(objTwo);
}

function deepFreeze<T>(inObj: T): T {
  Object.freeze(inObj);

  Object.getOwnPropertyNames(inObj).forEach((prop) => {
    if (
      inObj.hasOwnProperty(prop) &&
      inObj[prop] != null &&
      typeof inObj[prop] === "object" &&
      !Object.isFrozen(inObj[prop])
    ) {
      deepFreeze(inObj[prop]);
    }
  });
  return inObj;
}

function compareMaps(map1, map2) {
  let testVal;

  if (map1.size !== map2.size) {
    return false;
  }

  for (const [key, val] of map1) {
    testVal = map2.get(key);

    // in cases of an undefined value, make sure the key
    // actually exists on the object so there are no false positives
    if (testVal !== val || (testVal === undefined && !map2.has(key))) {
      return false;
    }

    // Test if both values are equel when parsed as a JSON string. (also works for objects)
    if (!nativeCompare(val, testVal)) {
      return false;
    }
  }
  return true;
}
