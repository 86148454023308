<div
  class="toggle"
  [class.toggle-on]="toggled"
  [class.toggle-disabled]="disabled"
>
  <label>
    <input type="checkbox" [checked]="toggled" [disabled]="disabled" />
    <span class="slider"></span>
  </label>
</div>

<span class="toggle-label">
  {{ toggled ? onLabel : offLabel }}
</span>
