<div id="main-container">
  <app-header id="header"></app-header>
  <div id="content">
    <router-outlet></router-outlet>
  </div>
  <app-footer id="footer"></app-footer>
</div>

<div
  class="page-loader"
  [class.page-loader-fadeOut]="(layoutService.showPageLoader$ | async) === false"
>
  <sms-loader></sms-loader>
</div>
