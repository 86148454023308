import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Grade, GradeConcentration, RawGrade } from "../../types/grade.model";
import { combineLatest, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { DataStore } from "src/app/types/data-store.model";
import { UnitService } from "../unit.service";
import { ConversionService } from "../conversion.service";

/**
 * This is the class that holds all commodities.
 * Important notice: the data in this store is not converted
 */
class GradeStore extends DataStore<RawGrade> {}

@Injectable({
  providedIn: "root",
})
export class GradesService {
  private _gradeStore$: GradeStore = new GradeStore();

  /**
   * Synchronises all grades with the backend
   */
  public readonly grades: Observable<Grade[]> = combineLatest([
    this._gradeStore$.getAllObservable(),
    this.unitService.preferredWeightUnit,
  ]).pipe(
    map(([grades, unit]) =>
      grades.map((j) => this.conversionService.toGrade(j))
    ),
    distinctUntilChanged(),
    shareReplay()
  );

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(
    private http: HttpClient,
    private unitService: UnitService,
    private conversionService: ConversionService
  ) {}

  /* ========================================
  Data access methods
  ======================================== */

  /**
   * Returns all currently registered grades
   */
  public get currentGrades(): Grade[] {
    return this._gradeStore$.getAll();
  }

  /**
   * Get a grade by its ID
   */
  public find(id: number): Grade {
    return this.conversionService.toGrade(this._gradeStore$.get(id)) || null;
  }

  /**
   * Returns true if a Grade with the passed id exists.
   * Otherwise returns false
   */
  public exists(id: number): boolean {
    return this._gradeStore$.exists(id);
  }

  /* ========================================
  Backend-api methods
  ======================================== */

  /**
   * Gets all grades
   */
  public async loadAll(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawGrade[]>(
          `${this.requestScheme}${environment.backendUrl}/grades`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._gradeStore$.upsertMany(response.body);
            resolve();
          } else {
            this._gradeStore$.next(new Map());
            reject();
          }
        });
    });
  }

  /**
   * Get a grade by its id
   */
  public async loadOne(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawGrade>(
          `${this.requestScheme}${environment.backendUrl}/grades/${id}`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._gradeStore$.upsert(response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Searches for the passed id in existing.
   * If existing, replaces it with the new grade
   */
  public async update(grade: Grade): Promise<void> {
    // To update the grade properties, send the new properties excluding ID, concentrations and constraints
    // Concentrations have their own handle.
    const convertedGrade: RawGrade = this.conversionService.fromGrade(grade);

    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.requestScheme}${environment.backendUrl}/grades/${grade.Id}`,
          convertedGrade,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this.loadOne(grade.Id);
            resolve();
          } else {
            reject();
          }
        });
    });
  }
}
