<div class="slider-container">
  <div class="slider-body">
    <span class="label">{{ max }}%</span>
    <div class="slider" #slider>
      <div class="grabber" (mousedown)="onGrab($event)">
        <div class="slider-tooltip" *ngIf="grabbing">
          {{ sliderValue | number }}
        </div>
      </div>
      <div
        class="slider-inner"
        [style.height]="height + '%'"
        #sliderInner
      ></div>
    </div>
    <span class="label">{{ min }}%</span>
  </div>
  <input
    type="number"
    [(ngModel)]="value"
    (keydown.enter)="valueChanged($event)"
    (focusout)="valueChanged($event)"
    [min]="min"
    [max]="max"
    step="0.1"
    *ngIf="displayInput"
  />
</div>
