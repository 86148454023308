import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";

import {
  Commodity,
  CommodityConstraint,
  CommodityConcentrationResponse,
  RawCommodity,
  CommodityPredictionStatus,
  CommodityUpdate,
} from "../../types/commodity.model";

import { environment } from "src/environments/environment";
import { UnitService } from "../unit.service";
import { DataStore } from "src/app/types/data-store.model";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { ConversionService } from "../conversion.service";

/**
 * This is the class that holds all commodities.
 * Important notice: the data in this store is not converted
 */
class CommodityStore extends DataStore<RawCommodity> {
  /**
   * Data Store extension to update commodity concentrations.
   * Just pass concentrations and/or predicted concentrations
   */
  public updateConcentrations(
    commodityId: number,
    update: CommodityConcentrationResponse
  ) {
    const commodity = this.getEditableItem(commodityId);

    commodity.Concentrations = update.Concentrations;
    commodity.PredictedConcentrations = update.PredictedConcentrations;

    this.upsert(commodity);
  }

  /**
   * Updates a commodity with the passed new values.
   */
  public updateCommodity(commodity: Partial<RawCommodity>) {
    const originalCommodity = this.getEditableItem(commodity.Id);

    const newCommodity = {
      ...originalCommodity,
      ...commodity,
    };

    this.upsert(newCommodity);
  }
}

@Injectable({
  providedIn: "root",
})
export class CommoditiesService {
  private _commodityStore$: CommodityStore = new CommodityStore();

  private _concentrationPredictionStatus$: BehaviorSubject<
    CommodityPredictionStatus[]
  > = new BehaviorSubject([]);

  private _commodityConstraints$: BehaviorSubject<
    CommodityConstraint[]
  > = new BehaviorSubject([]);

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  /**
   * Returnes all commodities as an observable, converting units based on user preference.
   */
  public readonly commodities: Observable<Commodity[]> = combineLatest([
    this._commodityStore$.getAllObservable(),
    this.unitService.preferredWeightUnit,
  ]).pipe(
    map(([commodities, unit]) =>
      commodities.map((i) => this.conversionService.toCommodity(i))
    ),
    distinctUntilChanged(),
    shareReplay()
  );

  /**
   * Returns all active commodities as an observable.
   */
  public readonly activeCommodities: Observable<
    Commodity[]
  > = this.commodities.pipe(map((i) => i.filter((j) => j.Active)));

  /**
   * Returns the copper pred. status as an observable
   */
  public readonly concentrationPredictionStatus: Observable<
    CommodityPredictionStatus[]
  > = this._concentrationPredictionStatus$.pipe(
    distinctUntilChanged(),
    shareReplay()
  );

  /**
   * Returns all commodity constraints available.
   */
  public readonly commodityConstraints: Observable<
    CommodityConstraint[]
  > = this._commodityConstraints$.pipe(
    map((constraints) =>
      constraints.map((i) => ({ ...i, Max: i.Max * 100, Min: i.Min * 100 }))
    ),
    distinctUntilChanged(),
    shareReplay()
  );

  constructor(
    private http: HttpClient,
    private unitService: UnitService,
    private conversionService: ConversionService
  ) { }

  /* ========================================
  Data access methods
  ======================================== */

  /**
   * Returns all currently registered commodities
   */
  public get currentCommodities(): Commodity[] {
    return this._commodityStore$.getAll();
  }

  public get currentActiveCommodities(): Commodity[] {
    return this._commodityStore$.getAll().filter((j) => j.Active);
  }

  /**
   * Get a commodity by its ID
   */
  public find(id: number): Commodity {
    return (
      this.conversionService.toCommodity(
        this._commodityStore$.getEditableItem(id)
      ) || null
    );
  }

  /**
   * Returns true if a Commodity with the passed name exists.
   * Otherwise returns false
   */
  public exists(id: number): boolean {
    return this._commodityStore$.exists(id);
  }

  /**
   * Cet current global constraints, optionally choose to only load active constraints
   */
  public getGlobalConstraints(
    onlyActive: boolean = false
  ): CommodityConstraint[] {
    return this._commodityConstraints$.value.map((i) => ({
      ...i,
      Min: i.Min * 100,
      Max: i.Max * 100,
    }));
  }

  /* ========================================
  Backend-api methods
  ======================================== */

  /**
   * Gets all commodities
   */
  public async loadAll(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawCommodity[]>(
          `${this.requestScheme}${environment.backendUrl}/commodities`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityStore$.upsertMany(response.body);
            resolve();
          } else {
            this._commodityStore$.next(new Map());
            reject();
          }
        });
    });
  }

  /**
   * Get a commodity by its id
   */
  public async loadOne(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawCommodity>(
          `${this.requestScheme}${environment.backendUrl}/commodities/${id}`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityStore$.upsert(response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Searches for the passed commodity name in existing commodities.
   * If existing, replaces it with the new commodity
   */
  public async update(
    commodityId: number,
    update: Partial<CommodityUpdate>
  ): Promise<boolean> {
    // Convert weight and price back to the units the backend expects
    const convertedUpdate: Partial<CommodityUpdate> = {
      SetDefaultConc: update.SetDefaultConc,
    };

    if (update.Concentrations) {
      convertedUpdate.Concentrations = update.Concentrations.map((i) => ({
        ElementId: i.ElementId,
        Value: this.unitService.convertToFraction(i.Value),
      }));
    }

    if (update.Constraints) {
      convertedUpdate.Constraints = {
        Min: this.unitService.convertToFraction(update.Constraints?.Min),
        Max: this.unitService.convertToFraction(update.Constraints?.Max),
      };
    }

    return new Promise<boolean>((resolve, reject) => {
      this.http
        .put<RawCommodity>(
          `${this.requestScheme}${environment.backendUrl}/commodities/${commodityId}`,
          convertedUpdate,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityStore$.updateCommodity(response.body);
            resolve(true);
          } else {
            reject(false);
          }
        });
    });
  }

  /**
   * Get concentrations of the commodity with the given id
   */
  public async getConcentrations(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<CommodityConcentrationResponse>(
          `${this.requestScheme}${environment.backendUrl}/commodities/${id}/concentrations`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityStore$.updateConcentrations(id, response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /* Commodity prediction status */

  /**
   * Load commodity prediction status of all commodities
   */
  public async loadAllPredictionStatus(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<CommodityPredictionStatus[]>(
          `${this.requestScheme}${environment.backendUrl}/commodities/concentrations_prediction_status`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._concentrationPredictionStatus$.next(response.body);
            resolve();
          } else {
            this._concentrationPredictionStatus$.next([]);
            reject();
          }
        });
    });
  }

  /**
   * Load the concentration prediction status for a single element for one commodity
   */
  public elementPredictionStatus(
    commodityId: number,
    elementId: number
  ): CommodityPredictionStatus {
    return this._concentrationPredictionStatus$.value.find(
      (state) =>
        state.CommodityId === commodityId && state.ElementId === elementId
    );
  }

  /**
   * Update the concentration prediction status for a single element for one commodity
   */
  public updatePredictionStatus(
    commodityId: number,
    elementId: number,
    status: boolean
  ): Promise<void> {
    const body = {
      Status: status,
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<CommodityPredictionStatus>(
          `${this.requestScheme}${environment.backendUrl}/commodities/${commodityId}/concentration_prediction_status/${elementId}`,
          body,
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            let newStatus = this._concentrationPredictionStatus$.value;

            let currentValue: CommodityPredictionStatus;

            newStatus = newStatus.filter((i) => {
              if (i.CommodityId !== commodityId && i.ElementId !== elementId) {
                currentValue = i;
                return true;
              } else {
                return false;
              }
            });

            newStatus.push({ ...currentValue, ...response.body });

            this._concentrationPredictionStatus$.next(newStatus);

            resolve();
          } else {
            reject();
          }
        }, reject);
    });
  }

  /**
   * Set a commodity active state, true = active, false = not active
   */
  public updateActiveState(commodityId: number, active: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<RawCommodity>(
          `${this.requestScheme}${environment.backendUrl}/commodities/${commodityId}/status`,
          { Active: active },
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityStore$.upsert(response.body);
            console.log(this._commodityStore$.value);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Load all commodity constraints
   */
  public async loadAllConstraints(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<CommodityConstraint[]>(
          `${this.requestScheme}${environment.backendUrl}/commodities/constraints`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityConstraints$.next(response.body);
            resolve();
          } else {
            this._commodityConstraints$.next([]);
            reject();
          }
        });
    });
  }

  /**
   * Update one or more commodity constraints
   */
  public async updateConstraints(
    constraints: CommodityConstraint[]
  ): Promise<void> {
    const payload = constraints.map((i) => ({
      ...i,
      Min: Number((i.Min / 100).toFixed(2)),
      Max: Number((i.Max / 100).toFixed(2)),
    }));

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<CommodityConstraint[]>(
          `${this.requestScheme}${environment.backendUrl}/commodities/constraints`,
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._commodityConstraints$.next(response.body);
            resolve();
          } else {
            this._commodityConstraints$.next([]);
            reject();
          }
        });
    });
  }
}
