import { Component, Input } from "@angular/core";
import {
  faCheckCircle,
  faSpinnerThird,
  faMinus,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

import { Cell, ColumnType } from "../data-table.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-data-table-cell",
  templateUrl: "./cell.component.html",
  styleUrls: ["./cell.component.scss"],
})
export class CellComponent {
  @Input("value") set setValue(value: string | number | boolean) {
    this.model = this.originalValue = value;
  }

  @Input() editable: boolean = false;
  @Input() type: ColumnType = null;
  @Input() cell: Cell = null;

  @Input() onChange: (event: Event, cell: Cell) => Promise<boolean>;

  public isUpdating: boolean = false;
  public editSuccess: boolean = null;

  public model: string | number | boolean = null;
  public originalValue: string | number | boolean = null;

  public faSpinnerThird = faSpinnerThird;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faMinus = faMinus;

  public valueChange(event: Event) {
    this.isUpdating = true;

    this.onChange(event, this.cell)
      .then((success: boolean) => {
        this.editSuccess = success;
      })
      .finally(() => {
        setTimeout(() => {
          if (!this.editSuccess) {
            this.model = this.originalValue;
          }

          this.editSuccess = null;
          this.isUpdating = false;
        }, 800);
      });
  }
}
