import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-toggle",
  templateUrl: "./toggle.component.html",
  styleUrls: ["./toggle.component.scss"],
})
export class ToggleComponent {
  @Input() public toggled: boolean = false;
  @Output() public toggledChange: EventEmitter<boolean> = new EventEmitter();

  @Input() public disabled: boolean = false;

  @Input() public onLabel: string = "On";
  @Input() public offLabel: string = "Off";

  constructor() {}

  @HostListener("click", ["$event"])
  public toggle() {
    this.toggledChange.emit(!this.toggled);
  }
}
