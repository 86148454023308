<div class="slider-container">
  <input
    type="number"
    [(ngModel)]="value"
    (keydown.enter)="valueChanged($event)"
    (focusout)="valueChanged($event)"
    [min]="min"
    [max]="max"
    step="0.1"
  />
  <div class="slider-body">
    <span class="label-min">{{ min }}%</span>
    <div class="slider" #slider>
      <div class="slider-inner" [style.width]="width + '%'" #sliderInner></div>
      <div
        class="grabber"
        (mousedown)="onGrab($event)"
        [style.z-index]="grabbing ? 4 : 3"
      >
        <div class="slider-tooltip" *ngIf="grabbing">
          {{ sliderValue | number }}
        </div>
      </div>
    </div>
    <span class="label-max">{{ max }}%</span>
  </div>
</div>
