import { Injectable, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export type PreferredWeightUnit = "st" | "gt" | "mt";

type UnitConversionFunction = () => number;

enum FACTORS {
  /** Explainations for abbreviations
   * MT = Metric Ton
   * ST = Short Ton
   * KG = Kilogram
   * GT - Gross Ton
   *
   */

  // Weights
  MT_ST = 1.10231, // used for calculating short tons => metric tons and vice-versa
  MT_KG = 1000, // used for calculating metric tons => kg and vice-versa
  MT_GT = 0.984207, // used for converting metric tons => gross tons

  GT_MT = 1.01605, // used for converting gross tons => metric tons
  GT_ST = 1.12, // used for converting gross tons => short tons
  GT_KG = 1016.05, // used for converting gross tons => kg

  ST_GT = 0.892857, // used for converting short tons => gross tons
  ST_MT = 0.907185, // used for converting short tons => metric tons
  ST_KG = 907.185, // used for converting short tons => kg

  KG_ST = 0.00110231, // used for converting kg => short tons
  KG_MT = 0.001, // used for converting kg => metric tons
  KG_GT = 0.000984207, // used for covnerting kg => gross tons
}

/**
 * This service is used for performing unit conversions.
 * Not to be confused with the conversionService, which performs the actual conversions for individual items,
 * the unit service defines and stores the global "preferred weight unit" and also defines factors for unit conversion.
 * Most importantly, it offers a functional interface for converting units like this:
 *
 * ```
 * const originalUnit = 100 // assume this is 100kg
 * const value = this.unitService.kilogram(originalUnit) // Define this unit as being in kilogram
 * const convertedUnit = value.toMetricTons() // All methods that can convert kg into another unit can be called like this.
 * ```
 * Ratios like `dollarPerKilogram` are also supported
 */
@Injectable({
  providedIn: "root",
})
export class UnitService {
  // This sets the preferred unit for displaying weights to either metric or short tons.
  // It is made public here so that other services can provide data based on this information.
  private _preferredWeightUnit: BehaviorSubject<PreferredWeightUnit> = new BehaviorSubject(
    "st"
  );

  public readonly preferredWeightUnit: Observable<PreferredWeightUnit> = this._preferredWeightUnit.asObservable();

  public currentPreferredWeightUnit: PreferredWeightUnit;

  public FACTORS = FACTORS;

  constructor() {
    this.preferredWeightUnit.subscribe((next) => {
      this.currentPreferredWeightUnit = next;
    });
  }

  public setPreferedWeightUnit(unit: PreferredWeightUnit) {
    if (unit === "st" || unit === "mt" || unit === "gt") {
      this._preferredWeightUnit.next(unit);
    }
  }

  public metricTon(
    x: number
  ): {
    toShortTons: UnitConversionFunction;
    toKilograms: UnitConversionFunction;
    toGrossTons: UnitConversionFunction;
  } {
    const toShortTons = () => x * FACTORS.MT_ST; // 1mt * 1.10231 = 1.10231...
    const toKilograms = () => x * FACTORS.MT_KG; // 1mt * 1000 = 1000
    const toGrossTons = () => x * FACTORS.MT_GT; // 1mt * 0.984207 = 0.984207
    return { toShortTons, toKilograms, toGrossTons };
  }

  public shortTon(
    x: number
  ): { toMetricTons: UnitConversionFunction; toKilograms; toGrossTons } {
    const toMetricTons = () => x * FACTORS.ST_MT; // 1st * 0.907185 = 0.907185.
    const toKilograms = () => x * FACTORS.ST_KG; // 1st * 907.185 = ‭907.185
    const toGrossTons = () => x * FACTORS.ST_GT; // 1st * 0.892857 = 0.892857
    return { toMetricTons, toKilograms, toGrossTons };
  }

  public kilogram(
    x: number
  ): { toMetricTons: UnitConversionFunction; toShortTons; toGrossTons } {
    const toMetricTons = () => x * FACTORS.KG_MT; // 1kg * 0.001 = 0.001
    const toShortTons = () => x * FACTORS.KG_ST; // 1kg * 0.00110231 = 0.00110231
    const toGrossTons = () => x * FACTORS.KG_GT; // 1gt * 0.000984207 = 0.000984207
    return { toMetricTons, toShortTons, toGrossTons };
  }

  public grossTon(
    x: number
  ): {
    toShortTons: UnitConversionFunction;
    toKilograms: UnitConversionFunction;
    toMetricTons: UnitConversionFunction;
  } {
    const toShortTons = () => x * FACTORS.GT_ST; // 1gt * 1.12 = 1.12...
    const toKilograms = () => x * FACTORS.GT_KG; // 1gt * 1016.05 = 1016.05
    const toMetricTons = () => x * FACTORS.GT_MT; // 1gt * 1.01605 = 1.01605
    return { toShortTons, toKilograms, toMetricTons };
  }

  public dollarPerKilogram(
    x: number
  ): {
    toDollarPerMetricTon: UnitConversionFunction;
    toDollarPerShortTon: UnitConversionFunction;
    toDollarPerGrossTon: UnitConversionFunction;
  } {
    const toDollarPerMetricTon = () => x * (1 / FACTORS.KG_MT); // 1$/kg * (1/0.001) = 1000
    const toDollarPerShortTon = () => x * (1 / FACTORS.KG_ST); // 1$/kg * (1/0.00110231) =  907.18474..
    const toDollarPerGrossTon = () => x * (1 / FACTORS.KG_GT); // 1$/kg * (1 / (0.000984207)) = 1 * 1016.0462 = 1016.0462...
    return { toDollarPerMetricTon, toDollarPerShortTon, toDollarPerGrossTon };
  }

  public dollarPerMetricTon(
    x: number
  ): {
    toDollarPerKilogram: UnitConversionFunction;
    toDollarPerShortTon: UnitConversionFunction;
    toDollarPerGrossTon: UnitConversionFunction;
  } {
    const toDollarPerKilogram = () => x * (1 / FACTORS.MT_KG); // 1$/MT * 0.001 = 0.001
    const toDollarPerShortTon = () => x * (1 / FACTORS.MT_ST); // 1$/MT * 907.18474 = 0.9071847
    const toDollarPerGrossTon = () => x * (1 / FACTORS.MT_GT); // 1$/MT * ( 1 / 0.9847207) = 1.0155163
    return { toDollarPerKilogram, toDollarPerShortTon, toDollarPerGrossTon };
  }

  public dollarPerShortTon(
    x: number
  ): {
    toDollarPerKilogram: UnitConversionFunction;
    toDollarPerMetricTon: UnitConversionFunction;
    toDollarPerGrossTon: UnitConversionFunction;
  } {
    const toDollarPerKilogram = () => x * (1 / FACTORS.ST_KG); // 1$/ST * (1/907.185) = 0.00110231..
    const toDollarPerMetricTon = () => x * (1 / FACTORS.ST_MT); // 1$/ST * (1/0.907.185) = 1.102310995..
    const toDollarPerGrossTon = () => x * (1 / FACTORS.ST_GT); // 1$/ST * (1/0.892857) = 1.1200001792
    return { toDollarPerKilogram, toDollarPerMetricTon, toDollarPerGrossTon };
  }

  public dollarPerGrossTon(
    x: number
  ): {
    toDollarPerKilogram: UnitConversionFunction;
    toDollarPerMetricTon: UnitConversionFunction;
    toDollarPerShortTon: UnitConversionFunction;
  } {
    const toDollarPerKilogram = () => x * (1 / FACTORS.GT_KG); // 1$/GT * (1/1016.05) = 0.00098420353..
    const toDollarPerMetricTon = () => x * (1 / FACTORS.GT_MT); // 1$/GT * (1/1.01605) = 0.984203533..
    const toDollarPerShortTon = () => x * (1 / FACTORS.GT_ST); // 1$/GT * (1/1.12) = 0.89285714...
    return { toDollarPerKilogram, toDollarPerMetricTon, toDollarPerShortTon };
  }

  /* ========================================
  Helper functions
  ======================================== */

  public convertToPercentage(value: number) {
    if (value == null || value == NaN) return null;

    return value * 100;
  }

  public convertToFraction(value: number) {
    return value / 100;
  }

  /* ========================================
  Methods for converting objects.. are now moved to the conversionService
  ======================================== */
}
