import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { SliderService } from "src/app/services/slider.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-range-slider",
  templateUrl: "./range-slider.component.html",
  styleUrls: ["./range-slider.component.scss"],
})
export class RangeSliderComponent implements OnChanges {
  @Input() valueMin: number;
  @Input() valueMax: number;

  @Input() min: number;
  @Input() max: number;

  @Output()
  onMaxValueChanged: EventEmitter<number> = new EventEmitter();
  @Output()
  onMinValueChanged: EventEmitter<number> = new EventEmitter();

  @ViewChild("slider") slider: ElementRef;
  @ViewChild("sliderInner") sliderInner: ElementRef;

  private sliderId: string; // The slider service id;

  // The width of the slider in percentage;
  public width: number;

  // left and right of the slider in percentage
  public left: number;
  public right: number;

  // Copy of the value input, used to reset inputs when needed
  public inputValueMin: number;
  public inputValueMax: number;

  public lastGrabbed: "min" | "max" = "min";

  /**
   * Variables for making the slider draggable
   */

  // Indecates if the slider is currently dragged.
  public grabbingMin: boolean;
  public grabbingMax: boolean;

  // The width of the slider when the drag event starts.
  // While the drag event is going we cannot get the width,
  // As we adjust it for the slider animation.
  public grabStartWidth: number;

  // The x start position of the slider drag event
  public px: number;

  // the result value of a slider drag event.
  // This is emitted after mouseUp of a slider event.
  public sliderValueMin: number;
  public sliderValueMax: number;

  constructor(private sliderService: SliderService) {
    this.valueMin = 0;
    this.valueMax = 0;
    this.min = 0;
    this.max = 100;

    this.inputValueMin = 0;
    this.inputValueMax = 0;

    this.width = 0;
    this.left = 0;
    this.right = 0;

    this.grabbingMin = false;
    this.grabbingMax = false;

    this.grabStartWidth = 0;
    this.px = 0;

    this.sliderValueMin = 0;
    this.sliderValueMax = 0;

    const onMouseMove = this.mouseMoveCallback.bind(this);
    const onMouseUp = this.mouseUpCallback.bind(this);

    this.sliderId = this.sliderService.register(onMouseMove, onMouseUp);
  }

  ngOnChanges() {
    this.inputValueMin = this.valueMin;
    this.inputValueMax = this.valueMax;

    this.width =
      ((this.valueMax - this.valueMin - this.min) * 100) /
      (this.max - this.min);

    // Calculate right and left offset percentage.
    // Right is 100 - the value, adjusted by min and max (e.g. 100 - 90% = 10)
    this.left = ((this.valueMin - this.min) * 100) / (this.max - this.min);
    this.right =
      100 - ((this.valueMax - this.min) * 100) / (this.max - this.min);
  }

  public mouseMoveCallback(event: MouseEvent) {
    if (this.grabbingMin) {
      const sliderBody = this.slider.nativeElement as HTMLDivElement;

      // The relative mouse offset in pixles
      const offsetX = event.clientX - this.px;

      // The percentage change.
      // E.g starting at 20, a -20% change means the slider was dragged to 0.
      const widthPercent = (offsetX / sliderBody.clientWidth) * 100;

      // To get the absolute percentage value, just add the current value to the percentage change.
      const minValue = widthPercent + (this.grabStartWidth - this.min);

      let left = 0;

      if (minValue <= 0) {
        left = 0;
      } else if (minValue >= 100) {
        left = 100;
      } else {
        left = minValue;
      }

      const percentage = (left * this.max) / 100;

      if (percentage <= this.valueMax) {
        this.left = left;
        this.sliderValueMin = percentage;
      }
    } else if (this.grabbingMax) {
      const sliderBody = this.slider.nativeElement as HTMLDivElement;

      // The relative mouse offset in pixles
      const offsetX = event.clientX - this.px;

      // Mouse offset in pixels, adjusted with the initial slider element with
      const widthChange = offsetX + this.grabStartWidth;

      let widthPercent = 0;

      // Convert the percentage value (pixel%) to width percent.
      // Only calculate the exact percentage if it is between 0 and 100, else it will be 0 or 100 anyways.
      if (widthChange <= 0) {
        widthPercent = 0;
      } else if (widthChange > sliderBody.clientWidth) {
        widthPercent = 100;
      } else if (widthChange <= sliderBody.clientWidth) {
        widthPercent = (widthChange / sliderBody.clientWidth) * 100;
      }

      // Percentage of right offset. An offset of 0% means 100% of the value
      // e.g. max value = 200; offset = 0 => sliderValue = 200;
      //      max value = 200; offset = 100 => sliderValue = 0;
      let right = 100 - (widthPercent / this.max) * this.max - this.left;

      if (right <= 0) {
        right = 0;
      } else if (right >= 100) {
        right = 100;
      } else {
        right = right;
      }

      const percentage = this.max - (this.right * this.max) / 100;

      if (percentage >= this.valueMin) {
        this.right = right;
        this.sliderValueMax = percentage;
      }
    } else {
      return;
    }
  }

  public mouseUpCallback(event: MouseEvent) {
    if (this.grabbingMin) {
      this.grabbingMin = false;

      const newValue = this.sliderValueMin.toFixed(3);
      this.valueMin = Number(newValue);

      this.onMinValueChanged.emit(Number(newValue));
      this.sliderService.activeSlider = null;
    } else if (this.grabbingMax) {
      this.grabbingMax = false;

      const newValue = this.sliderValueMax.toFixed(3);
      this.valueMax = Number(newValue);

      this.onMaxValueChanged.emit(Number(newValue));
      this.sliderService.activeSlider = null;
    }
  }

  public onMinGrab(event: MouseEvent) {
    // Mark the selected slider as active;
    this.sliderService.activeSlider = this.sliderId;

    this.grabbingMin = true;
    this.grabbingMax = false;

    this.grabStartWidth = this.valueMin;

    this.px = event.clientX;

    this.lastGrabbed = "min";

    event.preventDefault();
    event.stopPropagation();
  }

  public onMaxGrab(event: MouseEvent) {
    // Mark the selected slider as active;
    this.sliderService.activeSlider = this.sliderId;

    this.grabbingMax = true;
    this.grabbingMin = false;

    this.grabStartWidth = (this.sliderInner
      .nativeElement as HTMLDivElement).clientWidth;

    this.px = event.clientX;

    this.lastGrabbed = "max";

    event.preventDefault();
    event.stopPropagation();
  }

  public minValueChanged(e: Event) {
    const el = e.target as HTMLInputElement;
    const value = el.valueAsNumber;

    if (value > this.max || value < this.min) {
      alert(
        `The value ${value} is outside the valid range (min: ${this.min}, max: ${this.max})`
      );

      this.valueMin = this.inputValueMin;
      return;
    }

    this.onMinValueChanged.emit(value);
    this.sliderService.activeSlider = null;
  }

  public maxValueChanged(e: Event) {
    const el = e.target as HTMLInputElement;
    const value = el.valueAsNumber;

    if (value > this.max || value < this.min) {
      alert(
        `The value ${value} is outside the valid range (min: ${this.min}, max: ${this.max})`
      );

      this.valueMax = this.inputValueMax;
      return;
    }

    this.onMaxValueChanged.emit(value);
    this.sliderService.activeSlider = null;
  }
}
