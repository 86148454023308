import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import {
  faSort,
  faSortUp,
  faSortDown,
  faPlus,
  faTrash,
  faAngleLeft,
  faAngleRight,
  faMinus,
} from "@fortawesome/pro-regular-svg-icons";

import { UtilService } from "src/app/services/util.service";
import { ToastrService } from "ngx-toastr";

// Table-specific types:
export type ColumnId = string;
export type RowId = string | number;

// Table-specific interfaces
/**
 * Specifies the available types a table column can have
 * @export
 * @enum {number}
 */
export enum ColumnType {
  PARENT = "parent",
  STRING = "string",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
  // CATEGORY = "category",
  // SELECT = "select",
  // IMAGE = "image",
}

/**
 * Interface used to describe datatable columns
 * @export
 * @interface ColumnDefinition
 */
export interface ColumnDefinition {
  id: ColumnId;
  name: string;
  type: ColumnType;
  editable?: boolean;
  sticky?: boolean;
  children?: ChildColumnDefinition[];
  color?: string;
}

export interface ChildColumnDefinition {
  id: ColumnId;
  name: string;
  type: ColumnType;
  editable?: boolean;
  color?: string;
}

/**
 * Interface to define rows
 * @export
 * @interface RowDefinition
 */
export interface RowDefinition {
  id: number | string;
  // this allows the user to set properties like "name", "element" etc.
  // This can also be an array to support multiple input values for one column
  [index: string]: string | number | boolean | object;

  // If any new attributes are added for rows:
  // Keep in mind that the names of those properties could interfere with column ids.
}

/**
 * Interface for TableData options. In here all table options can be specified.
 * In the future we might want to do this on a component level as inputs.
 * @export
 * @interface Options
 */
export interface Options {
  /**
   * Must be a unique string, idealy a UUID.
   * If not given a random UUID will be generated.
   */
  tableId?: string;
  /**
   * Specify a column that is used to sort by default.
   */
  sorting?: {
    column: ColumnId | { parent: ColumnId; child: ColumnId };
    direction?: "ASC" | "DESC";
  };
}

/**
 * The main interface for a datatable. This alone is enough to create a full datatable.
 * While you can use this interface for defining the data, it needs to be put into the
 * component seperatley (so [column]="..." [rows]="..." etc.).
 * @export
 * @interface TableConfig
 */
export interface TableConfig {
  columns: ColumnDefinition[];
  rows: RowDefinition[];
  options?: Options;
}

export interface RowEditData {
  columnId: ColumnId;
  subId: ColumnId;
  rowId: RowId;
  oldValue: any;
  newValue: any;
}

// ==================================================
//                Internal Interfaces
// ==================================================
interface Row {
  cells: Cell[];
}

export interface Cell {
  value: string | number | boolean;
  rowId: RowId;
  columnId: ColumnId;
  subId: ColumnId;
  editable: boolean;
  type: ColumnType;
  parent: boolean;
  children?: Cell[];
  color?: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent /*implements OnChanges*/ {
  // ==================================================
  //                Input properties
  // ==================================================

  @Input("columns") set inputColumns(columns: ColumnDefinition[]) {
    if (columns != null && columns?.length > 0) {
      this.columns = this.sortColumns(columns);
    } else {
      this.columns = [];
    }
  }

  @Input("rows") set inputRows(rows: RowDefinition[]) {
    if (rows != null && rows?.length > 0) {
      this.tableRows = this.getTableRows(rows);
      this.rowDefinitions = rows;

      this.sortRows();
    }
  }

  @Input("options") set inputOptions(options: Options) {
    this.options = options;

    const optionValue = this.options?.sorting;

    if (optionValue?.column) {
      if (typeof optionValue?.column === "string") {
        this.sortingColumn = { parent: optionValue.column, child: null };
      } else {
        this.sortingColumn = {
          parent: optionValue.column.parent,
          child: optionValue.column.child,
        };
      }

      this.sortingOrder = optionValue.direction;
    } else {
      if (this.columns != null && this.columns?.length > 0) {
        this.sortingColumn = { parent: this.columns[0].id, child: null };
        this.sortingOrder = "DESC";
      }
    }

    this.sortRows();
  }

  @Input() onItemEdit: (edit: RowEditData) => void = null;

  // ==================================================
  //                Event listeners
  // ==================================================
  @Output() onItemClick: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteItemsButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() onAddItemButtonClick: EventEmitter<any> = new EventEmitter();

  // ==================================================
  //           Columns, rows and option data
  // ==================================================
  // Subjects used to maintain reactive data.

  // Mapped data the subscriptions provide
  public columns: ColumnDefinition[] = [];
  public rowDefinitions: RowDefinition[] = [];
  public options: Options = null;

  private tableRows: Row[] = [];

  public sortedRows: Row[] = [];

  private _sortingOrder: "ASC" | "DESC" = "ASC";
  private _sortingColumn: { parent: ColumnId; child: ColumnId } = null;

  // ==================================================
  //       Internals and option getters/setters
  // ==================================================
  public get tableId(): string {
    return this.options?.tableId || this.internalId;
  }

  public get sortingColumn(): { parent: ColumnId; child?: ColumnId } {
    return this._sortingColumn;
  }

  public set sortingColumn(id: { parent: ColumnId; child?: ColumnId }) {
    this._sortingColumn = {
      parent: id.parent,
      child: id.child ? id.child : null,
    };
  }

  public get sortingOrder(): "ASC" | "DESC" {
    return this._sortingOrder;
  }

  public set sortingOrder(order: "ASC" | "DESC") {
    this._sortingOrder = order;
  }

  // ==================================================
  //                Other internals
  // ==================================================

  // An internal override for allowing/forbidding inline editing.
  private allowEditing: boolean = true;

  private internalId: string;

  // Icons
  public faSort = faSort;
  public faSortUp = faSortUp;
  public faSortDown = faSortDown;
  public faPlus = faPlus;
  public faTrash = faTrash;
  public faAngleLeft = faAngleLeft;
  public faAngleRight = faAngleRight;
  public faMinus = faMinus;

  constructor(public utilService: UtilService, private toastr: ToastrService) {
    // Setting the internal id, used when no table id was given.
    this.internalId = this.utilService.uuidv4();
  }

  /* ngOnChanges() {
    // Check for input errors
    if (
      this.columns != null &&
      this.columns?.length > 0 &&
      this.rowDefinitions != null &&
      this.rowDefinitions?.length > 0
    ) {
      console.log(
        `[SMS DataTable] Checking ${this.tableId} for potential issues...`
      );
      const columnsEditable = this.checkColumnsEditable(this.columns);
      const rowIds = this.getAllRowIds(this.rowDefinitions);
      let issuesFound = false;
      let disableEditing = false;
      if (columnsEditable && rowIds.length !== this.rowDefinitions.length) {
        console.warn(
          "[SMS DataTable] Some columns are editable, yet not all rows have IDs.\
          This can lead to unexpected behaviour and loss of data!"
        );
        issuesFound = true;
        disableEditing = true;
      }
      if (!this.checkRowIdsValid(rowIds)) {
        console.warn(
          "[SMS DataTable] Detected issues with the row IDs.\
          Either there are duplicates or some rows do not have IDs defined."
        );
        issuesFound = true;
        disableEditing = true;
      }
      if (disableEditing) {
        console.info(
          "[SMS DataTable] because of the above error, editing for this table has been disabled."
        );
        this.allowEditing = false;
      }
      if (!issuesFound) {
        console.info(
          `%c[SMS DataTable] no issues found for table ${this.tableId}`,
          "color: #42BE65"
        );
      }
    }
  } */

  /**
   * Returnes a sorted array of Cells to use in the template.
   * @param {RowDefinition} row
   * @returns {Cell[]}
   * @memberof DataTableComponent
   */
  public getTableRows(rows: RowDefinition[]): Row[] {
    const tableRows: Row[] = [];

    if (this.columns && this.columns?.length > 0) {
      for (const row of rows) {
        const newRow: Row = { cells: [] };

        for (const column of this.columns) {
          const value = row[column.id];

          const cell: Cell = {
            value: null,
            rowId: row.id,
            columnId: column.id,
            subId: null,
            type: null,
            // If editing is globally disabled, editable is false.
            // If editing is generally allowed, check if the column is editable.
            // False by default.
            editable: this.allowEditing ? column?.editable || false : false,
            parent: null,
            color: column.color || null,
          };

          if (value != null) {
            if (column.type === ColumnType.PARENT) {
              const childCells: Cell[] = [];

              for (const childColumn of column.children) {
                const childValue = value[childColumn.id] ?? null;

                const childCell: Cell = {
                  value: childValue as string | number | boolean,
                  rowId: row.id,
                  columnId: column.id,
                  subId: childColumn.id,
                  type: childColumn.type,
                  // If editing is globally disabled, editable is false.
                  // If editing is generally allowed, check if the column is editable.
                  // False by default.
                  editable: this.allowEditing
                    ? column?.editable || false
                    : false,
                  parent: false,
                  color: childColumn.color || null,
                };

                childCells.push(childCell);
              }

              cell.children = childCells;
              cell.parent = true;
              cell.value = null;
              cell.type = ColumnType.PARENT;
            } else {
              cell.value = value as string | number | boolean;
              cell.parent = false;
              cell.type = column.type;
            }

            newRow.cells.push(cell);
          } else {
            cell.parent = false;
            cell.type = column.type;
            newRow.cells.push(cell);
          }
        }

        newRow.cells = this.sortCells(newRow.cells);

        tableRows.push(newRow);
      }
    }

    return tableRows;
  }

  public sortRows(): void {
    // Helper function to get the right cell for sorting
    const getSortingCell = (cells: Cell[]) => {
      if (this.sortingColumn) {
        for (const [i, cell] of cells.entries()) {
          if (cell.type === ColumnType.PARENT) {
            for (const [j, child] of cell.children.entries()) {
              const childId = {
                parent: child.columnId,
                child: child.subId,
              };

              if (
                this.utilService.compareObjects(childId, this.sortingColumn)
              ) {
                return child;
              }
            }
          } else {
            const id = {
              parent: cell.columnId,
              child: null,
            };

            if (this.utilService.compareObjects(id, this.sortingColumn)) {
              return cell;
            }
          }
        }
      } else {
        return cells[0];
      }
    };

    // Sort Ascending or Descending
    if (this.sortingOrder === "ASC") {
      this.sortedRows = this.tableRows.sort((a: Row, b: Row) => {
        // Different sorting algorithms for data types
        // check if date is null or undefined
        const cellA = getSortingCell(a.cells);
        const cellB = getSortingCell(b.cells);

        if (cellA?.value === cellB?.value) {
          return 0;
        }

        if (cellA?.value == null) {
          return -1;
        }

        if (cellB?.value == null) {
          return 1;
        }

        // check different types
        // TODO: Do this based on the cell.type property. To do this we need to first verify that it's correct.
        else if (typeof cellA.value === "number") {
          return (cellA.value as number) - (cellB.value as number);
        } else if (typeof cellA.value === "string") {
          return (cellB.value as string).localeCompare(
            cellA.value as string,
            undefined,
            {
              numeric: true,
              sensitivity: "base",
            }
          );
        } else {
          // Sorting by bool, date etc. is not implemented yet
          console.warn("Cannot sort by datatype " + typeof cellA.value);
        }
      });
    } else {
      this.sortedRows = this.tableRows.sort((a: Row, b: Row) => {
        // Different sorting algorithms for data types
        // check if date is null or undefined
        const cellA = getSortingCell(a.cells);
        const cellB = getSortingCell(b.cells);

        if (cellA?.value === cellB?.value) {
          return 0;
        }

        if (cellA?.value == null) {
          return 1;
        }

        if (cellB?.value == null) {
          return -1;
        }

        // check different types
        // TODO: Do this based on the cell.type property. To do this we need to first verify that it's correct.
        else if (typeof cellA.value === "number") {
          return (cellB.value as number) - (cellA.value as number);
        } else if (typeof cellA.value === "string") {
          return (cellA.value as string).localeCompare(
            cellB.value as string,
            undefined,
            {
              numeric: true,
              sensitivity: "base",
            }
          );
        } else {
          // Sorting by bool, date etc. is not implemented yet
          console.warn("Cannot sort by datatype " + typeof cellA.value);
        }
      });
    }
  }

  /** ==================================================
   *              Helper functions
   * ================================================== */

  /**
   * Returns IDs of all columns that are editable, null if none could be found.
   */
  private findEditableColumns(columns: ColumnDefinition[]): ColumnId[] {
    if (columns && columns.length > 0) {
      const editableColumns = columns.filter((i) => i.editable === true);

      if (editableColumns?.length > 0) {
        return editableColumns.map((i) => i.id);
      }
    }

    return null;
  }

  /**
   * Returns true if one or more columns are editable, false otherwise
   * @private
   * @param {ColumnDefinition[]} columns
   * @returns {boolean}
   * @memberof DataTableComponent
   */
  private checkColumnsEditable(columns: ColumnDefinition[]): boolean {
    if (columns && columns.length > 0) {
      const editableColumns = this.findEditableColumns(columns);

      if (editableColumns?.length > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * Gets the column or child column with the passed id.
   * @private
   * @param {ColumnId} id
   * @returns {(ColumnDefinition | ChildColumnDefinition)}
   * @memberof DataTableComponent
   */
  private getColumnById(
    id: ColumnId
  ): ColumnDefinition | ChildColumnDefinition {
    let matchingColumn: ColumnDefinition | ChildColumnDefinition;

    for (const column of this.columns) {
      if (column.id === id) {
        matchingColumn = column;
      } else {
        if (column?.children) {
          for (const childColumn of column.children) {
            if (childColumn.id === id) {
              matchingColumn = childColumn;
            }
          }
        }
      }
    }

    if (matchingColumn) {
      return matchingColumn;
    } else {
      console.warn(
        `[SMS DataTable] Unknown column ID ${id} (Table: ${this.tableId})`
      );
      return null;
    }
  }

  /**
   * Returns a list of RowIDs, or null otherwise.
   * @private
   * @param {RowDefinition[]} rows
   * @returns {RowId[]}
   * @memberof DataTableComponent
   */
  private getAllRowIds(rows: RowDefinition[]): RowId[] {
    return rows.map((i) => i.id);
  }

  /**
   * Returns true if the row ids are valid, false otherwise
   * @private
   * @param {RowId[]} rowIds
   * @returns
   * @memberof DataTableComponent
   */
  private checkRowIdsValid(rowIds: RowId[]): boolean {
    if (rowIds.length > 0) {
      const idSet = new Set(rowIds);

      const notAllowedKeys = rowIds.filter((i) => {
        // Check if the key is something other than the allowed types
        if (typeof i === "string" || typeof i === "number") {
          // Check if the key is an empty string
          if (i === "") {
            return true;
          }

          return false;
        } else {
          return true;
        }
      });

      const lengthValid = idSet.size === rowIds?.length;
      const contentValid = notAllowedKeys.length === 0;

      return lengthValid && contentValid;
    } else {
      return false;
    }
  }

  public getColumnCount() {
    let i = 0;

    for (const column of this.columns) {
      if (column.type === ColumnType.PARENT) {
        for (const child of column.children) {
          i++;
        }
      } else {
        i++;
      }
    }

    return i;
  }

  /**
   * Sorts the provided columns with the following criteria:
   * - The first key column that is found comes first, all other columns marked with "key" are ignored
   * - Then all columns marked as "sticky" follow.
   * - Then every other column
   * @private
   * @param {ColumnDefinition[]} columns
   * @returns {ColumnDefinition[]}
   * @memberof DataTableComponent
   */
  public sortColumns(columns: ColumnDefinition[]): ColumnDefinition[] {
    if (columns == null || columns == []) {
      return columns;
    }

    // Sort columns by stickyness: First sticky columns, then others.
    columns = columns.sort((a, b) => {
      if (a.sticky && b.sticky) {
        return 0;
      } else if (a.sticky) {
        return -1;
      } else if (b.sticky) {
        return 1;
      }
    });

    return columns;
  }

  /**
   * Sorts the given cells to match the column order.
   * @private
   * @param {Cell[]} cells
   * @returns {Cell[]}
   * @memberof DataTableComponent
   */
  private sortCells(cells: Cell[]): Cell[] {
    const columnOrder = this.columns.map((i) => i.id);

    const sortedCells = columnOrder.map((i) =>
      cells.find((j) => j.columnId === i)
    );

    return sortedCells;
  }

  public async cellChangedEvent(event: Event, cell: Cell) {
    const target = event.target as HTMLInputElement;

    // Only perform this when a onEdit function is registered
    if (this.onItemEdit) {
      let value: string | number | boolean = null;

      /*
        This block of code has two purposes:
          1. Casting the values correctly. Some inputs are of type number, some of bool and other are strings.
          2. Validating inputs in terms of type boundaries. 
              Validating by logical constraints must be done by the change handler passed into the datatable.
      */

      switch (cell.type) {
        case ColumnType.NUMBER:
          if (
            target.valueAsNumber == null ||
            Number.isNaN(target.valueAsNumber)
          ) {
            this.toastr.error("You can only input numbers in this field.");
          } else {
            value = target.valueAsNumber;
          }

          break;
        case ColumnType.STRING:
          if (target.value == null) {
            this.toastr.error("You can only input numbers in this field.");
          } else {
            value = target.value;
          }

          break;
        case ColumnType.BOOLEAN:
          if (target.value == null) {
            this.toastr.error("You can only input numbers in this field.");
          } else {
            value = target.value;
          }

          break;
        default:
          break;
      }

      // If a value could be parsed and is valid, call the onEdit method
      if (value != null) {
        const edit: RowEditData = {
          rowId: cell.rowId,
          columnId: cell.columnId,
          subId: cell.subId,
          oldValue: cell.value,
          newValue: value,
        };

        return this.onItemEdit(edit);
      }

      // If no value could be found, abort the update and cancel the edit.
      return false;
    } else {
      console.warn("Cannot update value: No callback function was registered");
      return false;
    }
  }

  public sortByColumn(id: { parent: ColumnId; child: ColumnId }) {
    // Check if already sorting by this column
    if (this.utilService.compareObjects(this.sortingColumn, id)) {
      // If so, reverse sorting direction
      if (this.sortingOrder === "ASC") {
        this._sortingOrder = "DESC";
      } else {
        this.sortingOrder = "ASC";
      }
      // Else sort by this column (by default sort ascending)
    } else {
      this.sortingColumn = id;
      this.sortingOrder = "ASC";
    }

    this.sortRows();
  }

  public get columnNumber(): number {
    let columns: number = 0;

    for (const column of this.columns) {
      if (column.type != ColumnType.PARENT) {
        columns++;
      } else {
        for (const childColumn of column.children) {
          columns++;
        }
      }
    }

    return columns;
  }
}
