<div class="slider-container">
  <input
    type="number"
    [(ngModel)]="valueMin"
    (keydown.enter)="minValueChanged($event)"
    [min]="min"
    [max]="valueMax || max"
  />
  <span class="label">{{ min }}%</span>
  <div class="slider-body">
    <div class="slider" #slider>
      <div
        class="grabber"
        (mousedown)="onMinGrab($event)"
        [style.left]="'calc(' + left + '% - 3px)'"
        [style.z-index]="lastGrabbed === 'min' ? 2 : 1"
      >
        <div class="slider-tooltip" *ngIf="grabbingMin">
          {{ sliderValueMin | number }}
        </div>
      </div>

      <div
        class="slider-inner"
        [style.left]="left + '%'"
        [style.right]="right + '%'"
        #sliderInner
      ></div>

      <div
        class="grabber"
        (mousedown)="onMaxGrab($event)"
        [style.right]="'calc(' + right + '% - 12px)'"
        [style.z-index]="lastGrabbed === 'max' ? 2 : 1"
      >
        <div class="slider-tooltip" *ngIf="grabbingMax">
          {{ sliderValueMax | number }}
        </div>
      </div>
    </div>
  </div>
  <span class="label">{{ max }}%</span>
  <input
    type="number"
    [(ngModel)]="valueMax"
    (keydown.enter)="maxValueChanged($event)"
    [min]="valueMin || min"
    [max]="max"
  />
</div>
