import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, distinctUntilChanged, shareReplay } from "rxjs/operators";
import {
  ConstraintTemplate,
  ConstraintTemplateCreate,
  ConstraintTemplateUpdate,
} from "src/app/types/constraint-template.model";
import { environment } from "src/environments/environment";
import { ConversionService } from "../conversion.service";
import { UnitService } from "../unit.service";
import { UtilService } from "../util.service";
import { SequencesService } from "./sequences.service";

@Injectable({
  providedIn: "root",
})
export class ConstraintTemplatesService {
  private _templates$: BehaviorSubject<
    ConstraintTemplate[]
  > = new BehaviorSubject([]);

  /**
   * Load all constraint templates as an observable
   */
  public readonly templates: Observable<
    ConstraintTemplate[]
  > = this._templates$.pipe(
    map((templates) =>
      templates.map((j) => this.conversionService.toConstraintTemplate(j))
    ),
    distinctUntilChanged(),
    shareReplay()
  );

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(
    private http: HttpClient,
    private conversionService: ConversionService,
    private utilService: UtilService,
    private unitService: UnitService,
    private sequencesService: SequencesService
  ) { }

  /**
   * Get the current templates
   */
  public get currentTemplates(): ConstraintTemplate[] {
    return this.utilService.cloneObject(
      this._templates$.value.map((i) =>
        this.conversionService.toConstraintTemplate(i)
      )
    );
  }

  /**
   * Find a template by id
   */
  public find(templateId: number): ConstraintTemplate {
    return this._templates$.value.find((i) => i.Id === templateId);
  }

  /**
   * Load all templates
   */
  public loadAll() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<ConstraintTemplate[]>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._templates$.next(response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Update a templates constraints, name and caster width
   */
  public update(template: Partial<ConstraintTemplateUpdate>) {
    const payload: Partial<ConstraintTemplateUpdate> = {
      ...template,
    };

    if (template.Constraints) {
      payload.Constraints = template.Constraints.map((i) => ({
        CommodityId: i.CommodityId,
        Min: this.unitService.convertToFraction(i.Min),
        Max: this.unitService.convertToFraction(i.Max),
      }));
    }

    return new Promise<void>((resolve, reject) => {
      this.http
        .put<ConstraintTemplate>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios/${template.Id}`,
          // For simplicity I convert to fraction here. Normally I would do that in the unit service,
          // But for that I would need to pass the whole scenario to this function and thats dumb.
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const oldTemplates = this._templates$.value.filter(
              (i) => i.Id !== template.Id
            );

            this._templates$.next([...oldTemplates, response.body]);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Create a new template
   */
  public create(template: ConstraintTemplateCreate) {
    const payload: ConstraintTemplateCreate = {
      ...template,
      Constraints: template.Constraints.map((i) => ({
        CommodityId: i.CommodityId,
        Min: Number(this.unitService.convertToFraction(i.Min).toFixed(3)),
        Max: Number(this.unitService.convertToFraction(i.Max).toFixed(3)),
      })),
    };

    return new Promise<void>((resolve, reject) => {
      this.http
        .post<ConstraintTemplate>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios`,
          // For simplicity I convert to fraction here. Normally I would do that in the unit service,
          // But for that I would need to pass the whole scenario to this function and thats dumb.
          payload,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 201) {
            console.log(response.body);
            this._templates$.next([...this._templates$.value, response.body]);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Delete an existing template
   */
  public delete(templateId: number) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .delete<ConstraintTemplate>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios/${templateId}`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            this._templates$.next(
              this._templates$.value.filter((i) => i.Id !== templateId)
            );
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * assign one or more heats to a constraint template
   */
  public assign(templateId: number, heatIds: number[], sequenceId: number) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put<void>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios/${templateId}/assign`,
          {
            HeatIds: heatIds,
          },
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            // Instead of reloading affected heats, we manually update the constraintType for them.
            this.sequencesService.updateHeatTemplate(
              "constraints",
              heatIds,
              templateId,
              sequenceId
            );
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Get all heats that are assigned to the given template
   */
  public assignedHeats(templateId: number): Promise<number[]> {
    return new Promise<number[]>((resolve, reject) => {
      this.http
        .get<{ HeatIds: number[] }>(
          `${this.requestScheme}${environment.backendUrl}/constraint_scenarios/${templateId}/assigned_heats`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            resolve(response.body.HeatIds);
          } else {
            reject([]);
          }
        });
    });
  }
}
