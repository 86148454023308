import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { TabComponent } from "./tab/tab.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent {
  @Output() public onTabSwitch: EventEmitter<
    string | number
  > = new EventEmitter();

  public tabs: TabComponent[] = [];

  public addTab(tab: TabComponent) {
    const activeTabs = this.tabs.filter((i) => i.active);

    if (activeTabs.length === 0) {
      if (!tab.disabled) {
        tab.active = true;
        this.onTabSwitch.emit(tab.id);
      }
    }

    this.tabs.push(tab);
  }

  public selectTab(tab: TabComponent) {
    // Resize event triggered to redraw plots
    // this is needed because plotly does not recognize the tab-switch and will not resize the plot to the full with
    // until the resize event is fired.
    let event;
    if (typeof Event === "function") {
      event = new Event("resize");
    } else {
      event = document.createEvent("Event");
      event.initEvent("resize", true, true);
    }

    window.dispatchEvent(event);

    this.tabs.forEach((t) => {
      t.active = false;
    });
    tab.active = true;

    // Trigger the "onclick" event of the tab component.
    tab.onClick.emit(tab.id);
    this.onTabSwitch.emit(tab.id);
  }
}
