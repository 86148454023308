import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SliderService } from "src/app/services/slider.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent {
  @Input() value: number;

  @Input() min: number;
  @Input() max: number;

  @Output() onChange: EventEmitter<number> = new EventEmitter();

  @ViewChild("slider") slider: ElementRef;
  @ViewChild("sliderInner") sliderInner: ElementRef;

  private sliderId: string; // The slider service id;

  // The width of the slider in percentage;
  public width: number;

  // Copy of the value input, used to reset inputs when needed
  public inputValue: number;

  /**
   * Variables for making the slider draggable
   */

  // Indecates if the slider is currently dragged.
  public grabbing: boolean;

  // The width of the slider when the drag event starts.
  // While the drag event is going we cannot get the width,
  // As we adjust it for the slider animation.
  public grabStartWidth: number;

  // The x start position of the slider drag event
  public px: number;

  // the result value of a slider drag event.
  // This is emitted after mouseUp of a slider event.
  public sliderValue: number;

  constructor(private sliderService: SliderService) {
    this.value = 0;
    this.min = 0;
    this.max = 100;

    this.inputValue = 0;
    this.width = 0;

    this.grabbing = false;
    this.grabStartWidth = 0;
    this.px = 0;
    this.sliderValue = 0;

    const onMouseMove = this.mouseMoveCallback.bind(this);
    const onMouseUp = this.mouseUpCallback.bind(this);

    this.sliderId = this.sliderService.register(onMouseMove, onMouseUp);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.inputValue = this.value;
    this.sliderValue = this.value;

    if (changes?.value?.currentValue == null) {
      this.width = 0;
      if (this.sliderInner) {
        this.sliderInner.nativeElement.style.width = "0";
      }
    } else {
      this.width = ((this.value - this.min) * 100) / (this.max - this.min);
    }
  }

  public onGrab(event: MouseEvent) {
    this.grabbing = true;
    this.sliderService.activeSlider = this.sliderId;

    // Safe the slider width at the moment of starting to grab the slider
    // Used to calculate the change % in the slider event.
    this.grabStartWidth = (this.sliderInner
      .nativeElement as HTMLDivElement).clientWidth;

    this.px = event.clientX;

    event?.preventDefault();
    event?.stopPropagation();
  }

  public mouseUpCallback(event: MouseEvent) {
    if (this.grabbing) {
      this.grabbing = false;

      const newValue = this.sliderValue.toFixed(3);
      this.value = Number(newValue);

      this.emitChange(Number(newValue));
      this.sliderService.activeSlider = null;
    }

    event?.preventDefault();
    event?.stopPropagation();
  }

  public mouseMoveCallback(event: MouseEvent) {
    if (this.grabbing) {
      const sliderBody = this.slider.nativeElement as HTMLDivElement;

      // The relative mouse offset in pixles
      const offsetX = event.clientX - this.px;

      // Mouse offset in pixels, adjusted with the initial slider element with
      const percentage = offsetX + this.grabStartWidth;

      let widthPercent = 0;

      // Convert the percentage value (pixel%) to width percent.
      // Only calculate the exact percentage if it is between 0 and 100, else it will be 0 or 100 anyways.
      if (percentage <= 0) {
        widthPercent = 0;
      } else if (percentage > sliderBody.clientWidth) {
        widthPercent = 100;
      } else if (percentage <= sliderBody.clientWidth) {
        widthPercent = (percentage / sliderBody.clientWidth) * 100;
      }

      // Set the slider element width to show the width change while grabbing the slider.
      this.width = widthPercent;

      // Convert the width percentage to an absolute value
      // e.g: If the slider max = 2,
      //    100% width = 2,
      //    50% width equals 1) ...
      this.sliderValue = (widthPercent / 100) * this.max;
    } else {
      return;
    }
  }

  public valueChanged(event: Event) {
    const el = event.target as HTMLInputElement;
    const value = el.valueAsNumber;

    if (value > this.max || value < this.min) {
      alert(
        `The value ${value} is outside the valid range (min: ${this.min}, max: ${this.max})`
      );

      this.value = this.inputValue;
      return;
    }

    this.emitChange(value);
    this.sliderService.activeSlider = null;

    event?.preventDefault();
    event?.stopPropagation();
  }

  public emitChange(value) {
    this.onChange.emit(value);
  }
}
