<!-- Splitting the card into "linking" and "not linking" with the ngIf is dirty,
  but the easiest solution for proper link handling i see.
  Setting the routerLink to "null" or something like this won't work.
  Importing the router and handling redirects inside this component also seems off to me.
-->

<div
  [ngClass]="cssConstructor"
  [routerLink]="link"
  [class.linking]="link"
  [class.withTitle]="title != null"
  *ngIf="link; else noLinkCard"
>
  <h3>
    {{ title }} <span *ngIf="unit">{{ unit }}</span>
  </h3>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <fa-icon *ngIf="icon" [icon]="icon" size="3x"></fa-icon>
</div>

<ng-template #noLinkCard>
  <div
    [ngClass]="cssConstructor"
    [class.linking]="link"
    [class.withTitle]="title != null"
  >
    <h3>
      {{ title }} <span *ngIf="unit">{{ unit }}</span>
    </h3>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <fa-icon *ngIf="icon" [icon]="icon" size="3x"></fa-icon>
  </div>
</ng-template>
