import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable, combineLatest } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

import { RawSequence, Sequence } from "src/app/types/sequences.model";
import {
  HeatProductionTarget,
  Heat,
  ConstraintsUpdate,
  ConstraintType,
} from "src/app/types/heat.model";
import { environment } from "src/environments/environment";
import { UnitService } from "../unit.service";
import { Constraint } from "src/app/types/heat.model";
import { DataStore } from "src/app/types/data-store.model";
import { ConversionService } from "../conversion.service";
import { UnassignHeatResponse } from "src/app/types/constraint-template.model";

/**
 * This is the class that holds all sequences.
 * Important notice: the data in this store is not converted
 */
class SequenceStore extends DataStore<RawSequence> { }

@Injectable({
  providedIn: "root",
})
export class SequencesService {
  private _sequenceStore$: SequenceStore = new SequenceStore();

  /**
   * Synchronises all sequences with the backend
   */
  public readonly sequences: Observable<Sequence[]> = combineLatest([
    this._sequenceStore$.getAllObservable(),
    this.unitService.preferredWeightUnit,
  ]).pipe(
    // This pipe is where all unit conversions for commodities is happening!
    // Be careful what you change here.

    // Convert every sequence into the right unit, also listen to preferredWeightUnit changes.
    map(([sequences, preferredWeightUnit]) => {
      return sequences?.map((sequence) =>
        this.conversionService.toSequence(sequence)
      );
    }),
    distinctUntilChanged(),
    shareReplay()
  );

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(
    private http: HttpClient,
    private unitService: UnitService,
    private conversionService: ConversionService
  ) { }

  /* ========================================
  Data access methods
  ======================================== */

  /**
   * Returns all currently registered sequences
   */
  public get currentSequences(): Sequence[] {
    return this._sequenceStore$
      .getAll()
      .map((i) => this.conversionService.toSequence(i));
  }

  /**
   * Get a sequence by its ID
   */
  public find(id: number): Sequence {
    return this.conversionService.toSequence(this._sequenceStore$.get(id));
  }

  /**
   * find a heat in the store by it's ID
   */
  public findHeat(id: number): Heat {
    for (const sequence of this._sequenceStore$.getAll()) {
      const convertedSequence = this.conversionService.toSequence(sequence);

      const tempHeat = convertedSequence.Heats.find((i) => i.Id === id);

      if (tempHeat && tempHeat != null) {
        return tempHeat;
      }
    }
  }

  /**
   * Returns true if a Sequence with the passed id exists.
   * Otherwise returns false
   */
  public exists(id: number) {
    return this._sequenceStore$.exists(id);
  }

  /**
   * Function to get an observable that updates the sequence when it updated.
   */
  public sequence(id: number): Observable<Sequence> {
    return this._sequenceStore$.getObservable(id).pipe(
      map((i) => this.conversionService.toSequence(i)),
      distinctUntilChanged(),
      shareReplay()
    );
  }

  /**
   * Find a single sequence in the store
   */
  private findSequenceId(heatId: number) {
    const sequenceId = this._sequenceStore$
      .getAll()
      .find((x) => x.Heats.find((y) => y.Id === heatId)).Id;

    return sequenceId;
  }

  /* ========================================
  Backend-api methods
  ======================================== */

  /**
   * Gets all Sequences and upsert them into the store
   */
  public async loadAll(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawSequence[]>(
          `${this.requestScheme}${environment.backendUrl}/sequences`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._sequenceStore$.upsertMany(response.body);
            resolve();
          } else {
            this._sequenceStore$.next(new Map());
            reject();
          }
        });
    });
  }

  /**
   * Load a single sequence and upsert it into the store
   */
  public async loadOne(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<RawSequence>(
          `${this.requestScheme}${environment.backendUrl}/sequences/${id}`,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            this._sequenceStore$.upsert(response.body);
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Update heat prod. targets for one heat.
   */
  public async updateHeatProductionTargets(
    sequenceId: number,
    heatId: number,
    elementId: number,
    value: { Min?: number; Aim?: number; Max?: number }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const newValue = value;

      newValue.Min =
        value.Min != null
          ? this.unitService.convertToFraction(value.Min)
          : null;
      newValue.Max =
        value.Max != null
          ? this.unitService.convertToFraction(value.Max)
          : null;
      newValue.Aim =
        value.Aim != null
          ? this.unitService.convertToFraction(value.Aim)
          : null;

      this.http
        .put(
          `${this.requestScheme}${environment.backendUrl}/heats/${heatId}/production_targets/${elementId}`,
          newValue,
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            const currentSequence = this._sequenceStore$.get(sequenceId);
            const currentHeats = currentSequence.Heats;

            const changedHeatIndex = currentHeats.findIndex(
              (i) => i.Id === heatId
            );

            currentHeats[changedHeatIndex] = {
              ...currentHeats[changedHeatIndex],
              FlexibleConcentrations: currentHeats[
                changedHeatIndex
              ].FlexibleConcentrations.map((i) => {
                if (i.ElementId === elementId) {
                  return {
                    ...i,
                    ...newValue,
                  };
                } else {
                  return i;
                }
              }),
            };

            this._sequenceStore$.upsert({
              ...currentSequence,
              Heats: currentHeats,
            });

            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Get commodity constraints for one heat
   */
  public async getHeatCommodityConstraints(
    heatId: number
  ): Promise<Constraint[]> {
    return new Promise<Constraint[]>((resolve, reject) => {
      this.http
        .get<Constraint[]>(
          `${this.requestScheme}${environment.backendUrl}/heats/${heatId}/constraints`,
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const constraints = response.body.map((i) => ({
              ...i,
              Min: this.unitService.convertToPercentage(i.Min),
              Max: this.unitService.convertToPercentage(i.Max),
              GlobalMin: this.unitService.convertToPercentage(i.GlobalMin),
              GlobalMax: this.unitService.convertToPercentage(i.GlobalMax),
            }));

            resolve(constraints);
          } else {
            reject(null);
          }
        });
    });
  }

  /**
   * Update heat constraints for one or more heats.
   * `constraints` is an array to update one or more constraints at once.
   */
  public async updateHeatConstraints(
    heatIds: number[],
    constraints: {
      CommodityId: number;
      Min: number;
      Max: number;
    }[]
  ): Promise<ConstraintsUpdate[]> {
    return new Promise<ConstraintsUpdate[]>((resolve, reject) => {
      const updatedValues = {
        HeatIds: heatIds,
        NewConcentrations: constraints.map((i) => ({
          CommodityId: i.CommodityId,
          Min: i.Min != null ? this.unitService.convertToFraction(i.Min) : null,
          Max: i.Max != null ? this.unitService.convertToFraction(i.Max) : null,
        })),
      };

      this.http
        .put<ConstraintsUpdate[]>(
          `${this.requestScheme}${environment.backendUrl}/heats/constraints`,
          updatedValues,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const returnedConstraints = response.body.map((i) => ({
              ...i,
              Min: this.unitService.convertToPercentage(i.Min),
              Max: this.unitService.convertToPercentage(i.Max),
            }));

            resolve(returnedConstraints);
          } else {
            reject(null);
          }
        });
    });
  }

  /**
   * Get Prod. targets for a heat by ID
   */
  public async getProductionTargets(heatId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<HeatProductionTarget[]>(
          `${this.requestScheme}${environment.backendUrl}/heats/${heatId}/production_targets`,
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            const sequenceId = this.findSequenceId(heatId);

            let currentSequence = this.find(sequenceId);
            let curentHeats = this._sequenceStore$.get(sequenceId).Heats;

            curentHeats = curentHeats.map((i) => {
              if (i.Id === heatId) {
                const elementConcentrations: HeatProductionTarget[] = [];
                response.body.forEach((x) => {
                  const y: HeatProductionTarget = {
                    HeatId: heatId,
                    ElementId: x.ElementId,
                    Aim: x.Aim,
                    Min: x.Min,
                    Max: x.Max,
                  };
                  elementConcentrations.push(y);
                });
                return { ...i, FlexibleConcentrations: elementConcentrations };
              } else {
                return i;
              }
            });

            currentSequence = {
              ...currentSequence,
              Heats: curentHeats,
            };

            this._sequenceStore$.upsert(currentSequence);

            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Reset all heat prod. targets for multiple heats
   */
  public async resetHeatProductionTargets(heatIds: number[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.requestScheme}${environment.backendUrl}/heats/production_targets/reset`,
          heatIds,
          { observe: "response" }
        )
        .subscribe((response) => {
          if (response.status === 200 || response.status === 204) {
            const sequences = [];

            for (const heat of heatIds) {
              const sequence = this.findHeat(heat)?.SequenceId;

              if (sequence) {
                if (!sequences.includes(sequence)) {
                  this.loadOne(sequence);
                  sequences.push(sequence);
                }
              }
            }

            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * Reset all commodity constraints for multiple heats
   */
  public async resetCommodityConstraints(heatIds: number[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.requestScheme}${environment.backendUrl}/heats/constraints/reset`,
          heatIds,
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 200) {
            for (const heat of heatIds) {
              this.getHeatCommodityConstraints(heat);
            }
            resolve();
          } else {
            reject();
          }
        });
    });
  }


  /**
   * Update the constraint type for one or more heats.
   * The constraint type is the "single source of truth" for what the optimizer should use.
   * This must be manually updated when the user assigns a template (this is done in the service method)
   */
  public updateHeatConstraintType(
    heats: number[],
    constraintType: ConstraintType
  ) {
    return new Promise<void>((resolve, reject) => {
      this.http
        .put(
          `${this.requestScheme}${environment.backendUrl}/heats/constraint_type`,
          {
            HeatIds: heats,
            ConstraintType: constraintType,
          },
          {
            observe: "response",
          }
        )
        .subscribe((response) => {
          if (response.status === 204) {
            for (const heat of heats) {
              const sequenceId = this.findSequenceId(heat);
              const sequence = this._sequenceStore$.getEditableItem(sequenceId);

              sequence.Heats = sequence.Heats.map((i) => {
                if (i.Id === heat) {
                  return {
                    ...i,
                    ConstraintType: constraintType,
                  };
                } else {
                  return i;
                }
              });

              this._sequenceStore$.upsert(sequence);
            }
            resolve();
          } else {
            reject();
          }
        });
    });
  }

  /**
   * This is a somewhat "hacky" workaround: Instead of reloading heats from the backend
   * after template assignment was changed, we inject the new template ID into the heat object.
   * Passing the sequence id into here just makes life easier, but is also not very nice.
   */
  public updateHeatTemplate(
    type: "constraints" | "parameters",
    heatIds: number[],
    templateId: number,
    sequenceId: number
  ) {
    const sequence = this._sequenceStore$.getEditableItem(sequenceId);

    if (sequence) {
      sequence.Heats = sequence.Heats.map((i) => {
        if (heatIds.includes(i.Id)) {
          if (type === "constraints") {
            return { ...i, ConstraintsScenarioId: templateId };
          } else if (type === "parameters") {
            return { ...i, ParametersScenarioId: templateId };
          }
        } else {
          return i;
        }
      });

      this._sequenceStore$.upsert(sequence);
    }
  }


  /**
   * 
   */
  public onConstraintTemplateUnassign(
    constraints: UnassignHeatResponse[],
    sequenceId: number
  ) {
    const sequence = this._sequenceStore$.getEditableItem(sequenceId);

    if (sequence) {
      sequence.Heats = sequence.Heats.map((i) => {
        const item = constraints.find((j) => j.HeatId === i.Id);

        if (item) {
          return {
            ...i,
            ConstraintsScenarioId: null,
            Constraints: item.Constraints.map((j) => ({
              ...i,
              Max: this.unitService.convertToFraction(j.Max),
              Min: this.unitService.convertToFraction(j.Min),
              GlobalMax: this.unitService.convertToFraction(j.GlobalMax),
              GlobalMin: this.unitService.convertToFraction(j.GlobalMin),
            })),
          };
        } else {
          return i;
        }
      });

      this._sequenceStore$.upsert(sequence);
    }
  }


  /**
   * Called when a process param template was unassigned from one or more heats.
   * It updates the heat models in the store, setting the ParameterScenarioId to null.
   */
  public onParameterTemplateUnassign(heats: number[], sequenceId: number) {
    const sequence = this._sequenceStore$.getEditableItem(sequenceId);

    if (sequence) {
      sequence.Heats = sequence.Heats.map((i) => {
        if (heats.includes(i.Id)) {
          return { ...i, ParametersScenarioId: null };
        } else {
          return i;
        }
      });

      this._sequenceStore$.upsert(sequence);
    }
  }

  /**
   * Adds a new run Id to the array of optimizations for a sequence.
   * This is mainly used after an optimization ran.
   */
  public async addRunId(sequenceId: number, id: number) {
    const sequence = this._sequenceStore$.getEditableItem(sequenceId);
    sequence.RunIds.push(id);
    this._sequenceStore$.upsert(sequence);
  }

  public async updateCommittedResultId(sequenceId: number, resultId: number) {
    const sequence = this._sequenceStore$.getEditableItem(sequenceId);
    sequence.CommittedResultId = resultId;
    this._sequenceStore$.upsert(sequence);
  }
}
