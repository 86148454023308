import { Component, OnDestroy } from "@angular/core";
import { DatabaseService } from "src/app/services/api/database.service";
import { TableStatus } from "src/app/types/database.model";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "src/app/services/modal.service";
import { AuthService } from "src/app/services/auth.service";
import { DatabaseStatus } from "src/app/types/database.model";
import {
  faMinusCircle,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { Subscription } from "rxjs";

/**
 * Main page footer
 */
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnDestroy {
  public lastDatabaseUpdateDate: string;
  public loggedIn: boolean = false;
  public tableList: TableStatus[];
  public databaseUpdate: DatabaseStatus;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faMinusCircle = faMinusCircle;

  public subscriptions: Subscription = new Subscription();

  constructor(
    private databaseService: DatabaseService,
    private toastr: ToastrService,
    private modalService: ModalService,
    public authService: AuthService
  ) {
    this.subscriptions.add(
      this.databaseService.databaseUpdate.subscribe((databaseUpdate) => {
        if (databaseUpdate) {
          this.lastDatabaseUpdateDate = moment(
            databaseUpdate.UpdateDate
          ).format("dddd, MMMM Do YYYY, h:mm:ss a");
          this.tableList = databaseUpdate.Tables;
        }
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openReloadDataModal() {
    this.modalService.open("reloadDataModal");
  }

  public closeReloadDataModal() {
    this.modalService.close("reloadDataModal");
  }

  public updateDatabase() {
    this.modalService.open("dataReloadRunningModal");

    this.closeReloadDataModal();

    this.databaseService
      .update()
      .then((res) => {
        this.toastr.success("Data successfully reloaded");
        this.modalService.close("dataReloadRunningModal");
      })
      .catch((err) => {
        console.error(err);
        this.toastr.error(
          "Failed to reload data",
          "Please reload the application and try again."
        );
        this.modalService.close("dataReloadRunningModal");
      });
  }
}
