import { Component, Input, OnChanges } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-skeleton-table", 
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class SkeletonTableComponent implements OnChanges {
  // tslint:disable-next-line:no-input-rename
  @Input("rows") nRows: number;
  // tslint:disable-next-line:no-input-rename
  @Input("columns") nColumns: number;

  public rows: any[] = [];
  public columns: any[] = [];

  constructor() {}

  ngOnChanges() {
    this.rows = Array(this.nRows);
    this.columns = Array(this.nColumns);
  }
}
