import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { TabsComponent } from "../tabs.component";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
})
export class TabComponent implements OnInit {
  public active: boolean = false;

  /**
   * The text displayed in the Tab header
   */
  @Input() name: string | number;

  /**
   * String or number used to uniquely identify the tab. It is YOUR responsibility to guarantee the uniqueness
   */
  @Input() id: string | number;

  @Input() disabled?: boolean = false;
  @Input() title?: string = null;

  /**
   * Emits the id of the clicked tab when a user clicked on the tab header
   */
  @Output("onClick") onClick?: EventEmitter<any> = new EventEmitter();

  constructor(private tabs: TabsComponent) {}

  ngOnInit() {
    this.tabs.addTab(this);
  }
}
