import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { UtilService } from "src/app/services/util.service";

export interface Item {
  id: number | string;
  value: any;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnChanges {
  @Input() items: Item[] = [];
  @Input() multiple: boolean = false;

  @Input("value") values: (number | string)[] = [];

  @Output("valueChange") valueChange: EventEmitter<
    (number | string)[]
  > = new EventEmitter();

  private _itemList: { item: Item; selected: boolean }[];

  public selectId: string;

  public faChevronDown = faChevronDown;

  constructor(private utilService: UtilService) {
    this._itemList = [];
    this.selectId = this.utilService.uuidv4();
  }

  public get selectedItems(): Item[] {
    if (this.values.length > 0 && this.values != null) {
      return this.items.filter((i) => this.values.includes(i.id));
    } else {
      return [];
    }
  }

  ngOnChanges(): void {
    this._itemList = this.items.map((i) => ({
      item: i,
      selected: this.values.includes(i.id),
    }));
  }

  public get itemList() {
    return this._itemList;
  }

  public onValueClick(item: Item, multiselect: boolean) {
    let itemIds: (string | number)[] = [];

    if (multiselect && this.multiple) {
      this._itemList = this._itemList.map((i) => ({
        item: i.item,
        selected: i.item.id === item.id ? !i.selected : i.selected,
      }));
    } else {
      this._itemList = this._itemList.map((i) => ({
        item: i.item,
        selected: i.item.id === item.id ? true : false,
      }));
    }

    itemIds = this._itemList.filter((i) => i.selected).map((i) => i.item.id);

    this.valueChange.emit(itemIds);
  }
}
