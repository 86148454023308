<div class="table" [style.--numColumns]="columnNumber">
  <ng-container *ngFor="let col of columns; index as i">
    <div
      class="table-header table-header-parent"
      [class.has-children]="col?.type == 'parent'"
      [style.--column-span]="col.children?.length"
      [style.border-color]="col.color"
    >
      <div class="table-header-text">{{ col.name }}</div>
      <div
        class="table-header-sortButton"
        (click)="sortByColumn({ parent: col.id, child: null })"
        *ngIf="col?.type !== 'parent'"
      >
        <ng-container
          *ngIf="
            utilService.compareObjects(sortingColumn, {
              parent: col.id,
              child: null
            });
            else defaultChildSortingIcon
          "
        >
          <fa-icon
            [icon]="sortingOrder === 'ASC' ? faSortUp : faSortDown"
          ></fa-icon>
        </ng-container>
        <ng-template #defaultChildSortingIcon>
          <fa-icon [icon]="faSort"></fa-icon>
        </ng-template>
      </div>
    </div>
    <ng-container
      *ngIf="col?.children?.length > 0; else tableHeaderPlaceholder"
    >
      <div
        class="table-header table-header-child"
        *ngFor="let child of col.children; index as j"
        [style.border-color]="child.color"
      >
        <div class="table-header-text">{{ child.name }}</div>
        <div
          class="table-header-sortButton"
          (click)="sortByColumn({ parent: col.id, child: child.id })"
        >
          <ng-container
            *ngIf="
              utilService.compareObjects(sortingColumn, {
                parent: col.id,
                child: child.id
              });
              else defaultChildSortingIcon
            "
          >
            <fa-icon
              [icon]="sortingOrder === 'ASC' ? faSortUp : faSortDown"
            ></fa-icon>
          </ng-container>
          <ng-template #defaultChildSortingIcon>
            <fa-icon [icon]="faSort"></fa-icon>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #tableHeaderPlaceholder>
      <div
        class="table-header table-header-child table-header-placeholder"
      ></div>
    </ng-template>
  </ng-container>

  <ng-container *ngFor="let row of sortedRows">
    <ng-container *ngFor="let cell of row.cells">
      <ng-container *ngIf="cell.parent === false; else subCells">
        <div class="table-cell" [class.table-cell-children]="cell.parent">
          <sms-data-table-cell
            [value]="cell.value"
            [editable]="cell.editable"
            [type]="cell.type"
            [cell]="cell"
            [onChange]="cellChangedEvent.bind(this)"
          ></sms-data-table-cell>
        </div>
      </ng-container>
      <ng-template #subCells>
        <div class="table-cell" *ngFor="let child of cell.children">
          <sms-data-table-cell
            [value]="child.value"
            [editable]="child.editable"
            [type]="child.type"
            [cell]="child"
            [onChange]="cellChangedEvent.bind(this)"
          ></sms-data-table-cell>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
