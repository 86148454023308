import { Injectable } from "@angular/core";

import { UtilService } from "./util.service";
import { PreferredWeightUnit, UnitService } from "./unit.service";

import { Commodity, RawCommodity } from "../types/commodity.model";
import { ElementSetting } from "../types/element.model";
import { RawGrade, Grade } from "../types/grade.model";
import {
  ProcessParameter,
  ProcessParameterUnit,
} from "../types/process-parameters.model";
import {
  RawSequence,
  Sequence,
  SequenceOptimizationResult,
} from "../types/sequences.model";
import { ConstraintTemplate } from "../types/constraint-template.model";
import {
  ParameterTemplate,
  ParameterTemplateItem,
} from "../types/parameter-template.model";
import { HeatOptimizationResult } from "../types/heat.model";

/**
 * This service is the single place where unit conversion should be done.
 * It defines methods to convert entire items fetched via the API.
 * For example, the toSequence method takes a Raw sequence fetched from the API
 * and performs all nessesary unit conversions on it.
 *
 * TODO: This service should be replaced once we use an NGXS store.
 * This is because right now it converts the units every time they are subscribed to from a component,
 * not once before they are stored.
 * When using NGRX we should convert units once in an action, before inserting the items into the store.
 */
@Injectable({
  providedIn: "root",
})
export class ConversionService {
  private preferredWeightUnit: PreferredWeightUnit = null;

  constructor(
    private unitService: UnitService,
    private utilService: UtilService
  ) {
    this.unitService.preferredWeightUnit.subscribe((next) => {
      this.preferredWeightUnit = next;
    });
  }

  // Sequences
  public toSequence(sequence: RawSequence): Sequence {
    if (sequence == null) {
      return null;
    }

    const clonedSequence: Sequence = this.utilService.cloneObject(sequence);

    const convertWeight = (weight: number) => {
      const value = this.unitService.kilogram(weight);

      const target = this.preferredWeightUnit;

      switch (target) {
        case "mt":
          return value.toMetricTons();
        case "st":
          return value.toShortTons();
        case "gt":
          return value.toGrossTons();
      }
    };

    clonedSequence.Heats = clonedSequence.Heats.map((i) => ({
      ...i,
      Weight: convertWeight(i.Weight),
      MeasuredCopper: i.MeasuredCopper,
      FlexibleConcentrations: i.FlexibleConcentrations.map((j) => ({
        ...j,
        Min: this.unitService.convertToPercentage(j.Min),
        Max: this.unitService.convertToPercentage(j.Max),
        Aim: this.unitService.convertToPercentage(j.Aim),
      })),
    }));

    return clonedSequence;
  }

  public fromSequence(sequence: Sequence): RawSequence {
    if (sequence == null) {
      return null;
    }

    const clonedSequence: RawSequence = this.utilService.cloneObject(sequence);

    const convertWeight = (weight: number) => {
      let value;
      const base = this.preferredWeightUnit;

      switch (base) {
        case "mt":
          value = this.unitService.metricTon(weight);
          break;
        case "st":
          value = this.unitService.shortTon(weight);
          break;
        case "gt":
          value = this.unitService.grossTon(weight);
          break;
      }

      return value.toKilograms();
    };

    clonedSequence.Heats = clonedSequence.Heats.map((i) => ({
      ...i,
      Weight: convertWeight(i.Weight),
      MeasuredCopper: i.MeasuredCopper,
      FlexibleConcentrations: i.FlexibleConcentrations.map((j) => ({
        ...j,
        Min: this.unitService.convertToFraction(j.Min),
        Max: this.unitService.convertToFraction(j.Max),
        Aim: this.unitService.convertToFraction(j.Aim),
      })),
    }));

    return clonedSequence;
  }

  // Commodity

  public toCommodity(commodity: RawCommodity): Commodity {
    if (commodity == null) {
      return null;
    }

    const clonedCommodity: Commodity = this.utilService.cloneObject(commodity);

    const price = this.unitService.dollarPerKilogram(commodity.Price);
    const inventory = this.unitService.kilogram(commodity.Inventory);

    const target = this.preferredWeightUnit;

    switch (target) {
      case "mt":
        clonedCommodity.Price = price.toDollarPerMetricTon();
        clonedCommodity.Inventory = inventory.toMetricTons();
        break;
      case "st":
        clonedCommodity.Price = price.toDollarPerShortTon();
        clonedCommodity.Inventory = inventory.toShortTons();
        break;
      case "gt":
        clonedCommodity.Price = price.toDollarPerGrossTon();
        clonedCommodity.Inventory = inventory.toGrossTons();
        break;
      default:
        clonedCommodity.Price = price.toDollarPerShortTon();
        clonedCommodity.Inventory = inventory.toShortTons();
        break;
    }

    clonedCommodity.Concentrations = clonedCommodity.Concentrations.map(
      (i) => ({
        ...i,
        Value: Number(this.unitService.convertToPercentage(i.Value).toFixed(3)),
        DefaultValue: Number(
          this.unitService.convertToPercentage(i.DefaultValue).toFixed(3)
        ),
      })
    );

    clonedCommodity.PredictedConcentrations = clonedCommodity.PredictedConcentrations.map(
      (i) => ({
        ...i,
        Mean: this.unitService.convertToPercentage(i.Mean),
        UpperConfBound: this.unitService.convertToPercentage(i.UpperConfBound),
        LowerConfBound: this.unitService.convertToPercentage(i.LowerConfBound),
        Std: this.unitService.convertToPercentage(i.Std),
      })
    );

    return clonedCommodity;
  }

  public fromCommodity(commodity: Commodity): RawCommodity {
    if (commodity == null) {
      return null;
    }

    const rawCommodity: RawCommodity = this.utilService.cloneObject(commodity);

    let price;
    let inventory;

    const base = this.preferredWeightUnit;

    switch (base) {
      case "mt":
        price = this.unitService.dollarPerMetricTon(commodity.Price);
        inventory = this.unitService.metricTon(commodity.Inventory);
        break;
      case "st":
        price = this.unitService.dollarPerShortTon(commodity.Price);
        inventory = this.unitService.shortTon(commodity.Inventory);
        break;
      case "gt":
        price = this.unitService.dollarPerGrossTon(commodity.Price);
        inventory = this.unitService.grossTon(commodity.Inventory);
        break;
      default:
        price = this.unitService.dollarPerShortTon(commodity.Price);
        inventory = this.unitService.shortTon(commodity.Inventory);
        break;
    }

    rawCommodity.Price = price.toDollarPerKilogram();
    rawCommodity.Inventory = inventory.toShortTons();

    rawCommodity.Concentrations = rawCommodity.Concentrations.map((i) => ({
      ...i,
      Value: Number(this.unitService.convertToFraction(i.Value).toFixed(3)),
    }));

    rawCommodity.PredictedConcentrations = rawCommodity.PredictedConcentrations.map(
      (i) => ({
        ...i,
        Mean: this.unitService.convertToFraction(i.Mean),
        UpperConfBound: this.unitService.convertToFraction(i.UpperConfBound),
        LowerConfBound: this.unitService.convertToFraction(i.LowerConfBound),
        Std: this.unitService.convertToFraction(i.Std),
      })
    );

    return rawCommodity;
  }

  // Grades

  public toGrade(grade: RawGrade): Grade {
    if (grade == null) {
      return null;
    }

    const clonedGrade = this.utilService.cloneObject(grade);

    clonedGrade.ElementConcentrations = clonedGrade.ElementConcentrations.map(
      (i) => ({
        ...i,
        Aim: this.unitService.convertToPercentage(i.Aim),
        Min: this.unitService.convertToPercentage(i.Min),
        Max: this.unitService.convertToPercentage(i.Max),
      })
    );

    return clonedGrade;
  }

  public fromGrade(grade: Grade): RawGrade {
    if (grade == null) {
      return null;
    }

    const clonedGrade = this.utilService.cloneObject(grade);

    clonedGrade.ElementConcentrations = clonedGrade.ElementConcentrations.map(
      (i) => ({
        ...i,
        Aim: this.unitService.convertToFraction(i.Aim),
        Min: this.unitService.convertToFraction(i.Min),
        Max: this.unitService.convertToFraction(i.Max),
      })
    );

    return clonedGrade;
  }

  public toElementSetting(elementSetting: ElementSetting) {
    if (elementSetting == null) {
      return null;
    }

    const clonedSetting: ElementSetting = this.utilService.cloneObject(
      elementSetting
    );

    clonedSetting.Margin = this.unitService.convertToPercentage(
      clonedSetting.Margin
    );

    return clonedSetting;
  }

  public fromElementSetting(elementSetting: ElementSetting) {
    if (elementSetting == null) {
      return null;
    }

    const clonedSetting: ElementSetting = this.utilService.cloneObject(
      elementSetting
    );

    clonedSetting.Margin = this.unitService.convertToFraction(
      clonedSetting.Margin
    );

    return clonedSetting;
  }

  public toParameter(parameter: ProcessParameter) {
    const clonedParameter: ProcessParameter = this.utilService.cloneObject(
      parameter
    );

    if (clonedParameter.Concentrations) {
      clonedParameter.Concentrations = clonedParameter.Concentrations.map(
        (i) => ({
          ...i,
          Value: Number(this.unitService.convertToPercentage(i.Value).toFixed(2)),
        })
      );
    }

    return clonedParameter;
  }

  public toConstraintTemplate(template: ConstraintTemplate) {
    const res: ConstraintTemplate = {
      ...template,
      Constraints: template.Constraints.map((i) => ({
        ...i,
        Min: this.unitService.convertToPercentage(i.Min),
        Max: this.unitService.convertToPercentage(i.Max),
        GlobalMin: this.unitService.convertToPercentage(i.GlobalMin),
        GlobalMax: this.unitService.convertToPercentage(i.GlobalMax),
      })),
    };

    return res;
  }

  public fromConstraintTemplate(template: ConstraintTemplate) {
    const res: ConstraintTemplate = {
      ...template,
      Constraints: template.Constraints.map((i) => ({
        ...i,
        Min: this.unitService.convertToFraction(i.Min),
        Max: this.unitService.convertToFraction(i.Max),
        GlobalMin: this.unitService.convertToFraction(i.GlobalMin),
        GlobalMax: this.unitService.convertToFraction(i.GlobalMax),
      })),
    };

    return res;
  }

  public convertProcessParameterUnits(
    parameter: Partial<ParameterTemplateItem>,
    direction: "toSi" | "fromSi"
  ) {
    if (direction === "fromSi") {
      // Match all parameters with this unit
      if (parameter.Unit === ProcessParameterUnit.kg_kg_steel) {
        let convertedAmount: number;

        if (this.preferredWeightUnit === "mt") {
          convertedAmount = parameter.Amount / this.unitService.FACTORS.KG_MT;
        } else if (this.preferredWeightUnit === "st") {
          convertedAmount = parameter.Amount / this.unitService.FACTORS.KG_ST;
        } else if (this.preferredWeightUnit === "gt") {
          convertedAmount = parameter.Amount / this.unitService.FACTORS.KG_GT;
        }

        return {
          ...parameter,
          Amount: Number(convertedAmount.toFixed(2)),
          Unit: ProcessParameterUnit.kg_ton_steel,
        };
      }

      // Match only Natural Gas
      if (parameter.MaterialId === 1) {
        let amount;

        amount = Number((parameter.Amount * (60 * 60)).toFixed());

        return {
          ...parameter,
          Amount: amount, // Seconds => Hours (and vice-versa)
          Unit: ProcessParameterUnit.m3_h,
        };
      }

      // Match only Fine Coal
      if (parameter.MaterialId === 5402) {
        let amount;

        amount = Number((parameter.Amount * 60).toFixed());

        return {
          ...parameter,
          Amount: amount, // Seconds => Minutes (and vice-versa)
          Unit: ProcessParameterUnit.kg_m,
        };
      }

      return parameter;
    } else if (direction === "toSi") {
      if (parameter.Unit === ProcessParameterUnit.kg_ton_steel) {
        let convertedAmount: number;

        if (this.preferredWeightUnit === "mt") {
          convertedAmount = parameter.Amount * this.unitService.FACTORS.KG_MT;
        } else if (this.preferredWeightUnit === "st") {
          convertedAmount = parameter.Amount * this.unitService.FACTORS.KG_ST;
        } else if (this.preferredWeightUnit === "gt") {
          convertedAmount = parameter.Amount * this.unitService.FACTORS.KG_GT;
        }

        return {
          ...parameter,
          Amount: convertedAmount,
          Unit: ProcessParameterUnit.kg_kg_steel,
        };
      }

      // Match only Natural Gas
      if (parameter.MaterialId === 1) {
        let amount;

        amount = (parameter.Amount / (60 * 60)); // Hours => Seconds

        return {
          ...parameter,
          Amount: amount,
          Unit: ProcessParameterUnit.m3_s,
        };
      }

      // Match only Fine Coal
      if (parameter.MaterialId === 5402) {
        let amount;

        amount = (parameter.Amount / 60); // Minutes => Seconds

        return {
          ...parameter,
          Amount: amount,
          Unit: ProcessParameterUnit.kg_s,
        };
      }

      return parameter;
    }
  }

  public toParameterTemplate(template: ParameterTemplate): ParameterTemplate {
    let clonedTemplate: ParameterTemplate = this.utilService.cloneObject(
      template
    );

    clonedTemplate.CreateDate = new Date(clonedTemplate.CreateDate);

    clonedTemplate.Parameters = clonedTemplate.Parameters.map((i) => ({
      ...i,
      ...this.convertProcessParameterUnits(i, "fromSi"),
    }));

    return clonedTemplate;
  }

  public fromParameterTemplate(template: ParameterTemplate) {
    let clonedTemplate: ParameterTemplate = this.utilService.cloneObject(
      template
    );

    clonedTemplate.Parameters = clonedTemplate.Parameters.map((i) => {
      // Match all parameters with this unit
      if (i.Unit === ProcessParameterUnit.kg_kg_steel) {
        let convertedAmount;

        if (this.preferredWeightUnit === "mt") {
          convertedAmount = i.Amount / this.unitService.FACTORS.KG_MT;
        } else if (this.preferredWeightUnit === "st") {
          convertedAmount = i.Amount / this.unitService.FACTORS.KG_ST;
        } else if (this.preferredWeightUnit === "gt") {
          convertedAmount = i.Amount / this.unitService.FACTORS.KG_GT;
        }

        return {
          ...i,
          Amount: convertedAmount,
        };
      }

      // Match only Natural Gas
      if (i.MaterialId === 1) {
        return {
          ...i,
          Amount: i.Amount / (60 * 60), // Hours => Seconds
        };
      }

      // Match only Fine Coal
      if (i.MaterialId === 5402) {
        return {
          ...i,
          Amount: i.Amount / 60, // Minutes => Seconds
        };
      }

      return i;
    });

    return clonedTemplate;
  }

  public fromSequenceOptimizationResult(result: SequenceOptimizationResult) {
    const clonedResult = this.utilService.cloneObject(result);

    const res = {
      ...clonedResult,
      TotalEnergyConsumption: clonedResult.TotalEnergyConsumption / 1000,
      Errors: clonedResult.Errors.map((j) => ({
        ...j,
        ErrorArgs: {
          ...j.ErrorArgs,
          Lower:
            j.ErrorArgs?.Lower != null
              ? this.unitService.convertToPercentage(j.ErrorArgs?.Lower)
              : undefined,
          Upper:
            j.ErrorArgs?.Upper != null
              ? this.unitService.convertToPercentage(j.ErrorArgs?.Upper)
              : undefined,
          Body:
            j.ErrorArgs?.Body != null
              ? this.unitService.convertToPercentage(j.ErrorArgs?.Body)
              : undefined,
          EqValue:
            j.ErrorArgs?.EqValue != null
              ? this.unitService.convertToPercentage(j.ErrorArgs?.EqValue)
              : undefined,
          Max:
            j.ErrorArgs?.Max != null
              ? this.unitService.convertToPercentage(j.ErrorArgs?.Max)
              : undefined,
        },
      })),
    };

    return res;
  }

  public fromHeatOptimizationResult(result: HeatOptimizationResult) {
    const convertWeight = (weight: number) => {
      const value = this.unitService.kilogram(weight);

      const target = this.preferredWeightUnit;

      switch (target) {
        case "mt":
          return value.toMetricTons();
        case "st":
          return value.toShortTons();
        case "gt":
          return value.toGrossTons();
      }
    };

    const clonedResult = this.utilService.cloneObject(result);

    const res = {
      ...clonedResult,
      EnergyConsumption: clonedResult.EnergyConsumption / 1000, // Converting energy consumption into MWh from Kwh
      EstimatedYield: this.unitService.convertToPercentage(
        clonedResult.EstimatedYield
      ),
      CommoditiesMix: clonedResult.CommoditiesMix?.map((k) => ({
        ...k,
        Weight: convertWeight(k.Weight),
        Value: this.unitService.convertToPercentage(k.Value),
        HeatLimitMax: this.unitService.convertToPercentage(k.HeatLimitMax),
        HeatLimitMin: this.unitService.convertToPercentage(k.HeatLimitMin),
      })),
      EstimatedChemistry: clonedResult.EstimatedChemistry?.map((l) => ({
        ...l,
        Value: this.unitService.convertToPercentage(l.Value),
        TargetMin: this.unitService.convertToPercentage(l.TargetMin),
        TargetAim: this.unitService.convertToPercentage(l.TargetAim),
        TargetMax: this.unitService.convertToPercentage(l.TargetMax),
      })),
    };

    return res;
  }
}
