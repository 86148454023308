import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "src/app/guards/auth.guard";

import { PageNotFoundComponent } from "./views/page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "/",
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    data: { title: "Dashboard" },
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "commodities",
    loadChildren: () =>
      import("./commodities/commodities.module").then(
        (m) => m.CommoditiesModule
      ),
    data: { title: "Commodities" },
    canActivate: [AuthGuard],
  },
  {
    path: "grades",
    loadChildren: () =>
      import("./grades/grades.module").then((m) => m.GradesModule),
    data: { title: "Grades" },
    canActivate: [AuthGuard],
  },
  {
    path: "sequences",
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "edit", pathMatch: "full" },
      {
        path: "edit",
        loadChildren: () =>
          import("./sequences/sequences.module").then((m) => m.SequencesModule),
        data: { title: "Sequences" },
      },
      {
        path: "optimizations",
        loadChildren: () =>
          import("./optimizations/optimizations.module").then(
            (m) => m.OptimizationsModule
          ),
        data: { title: "Optimizations" },
      },
    ],
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    data: { title: "Authorization" },
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
    data: { title: "Settings" },
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
