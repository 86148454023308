<ng-container *ngIf="editable; else notEditableCell">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'string'">
      <input
        type="text"
        [(ngModel)]="model"
        (keyup.enter)="valueChange($event)"
        [disabled]="isUpdating"
        [class.input-error]="editSuccess === false"
        [class.input-success]="editSuccess === true"
      />
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
      <input
        type="number"
        [(ngModel)]="model"
        (keyup.enter)="valueChange($event)"
        [disabled]="isUpdating"
        [class.input-error]="editSuccess === false"
        [class.input-success]="editSuccess === true"
      />
    </ng-container>
    <ng-container *ngSwitchCase="'boolean'">
      <label class="checkbox-label">
        <input
          type="radio"
          [checked]="model"
          (change)="valueChange($event)"
          [disabled]="isUpdating"
          [class.input-error]="editSuccess === false"
          [class.input-success]="editSuccess === true"
        />
        <span class="checkbox-custom"></span>
      </label>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <input
        type="date"
        [value]="model"
        [disabled]="isUpdating"
        [class.input-error]="editSuccess === false"
        [class.input-success]="editSuccess === true"
      />
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #notEditableCell>
  <div
    class="empty-cell"
    *ngIf="model == null; else defaultCellSwitch"
    [ngClass]="{
      string: type === 'string',
      number: type === 'number',
      boolean: type === 'boolean',
      date: type === 'date'
    }"
  >
    -
  </div>
  <ng-template #defaultCellSwitch>
    <ng-container [ngSwitch]="type">
      <div *ngSwitchCase="'string'" class="string">
        {{ model }}
      </div>
      <div *ngSwitchCase="'number'" class="number">
        {{ $any(model) | number:'1.2'}}
      </div>
      <div *ngSwitchCase="'boolean'" class="boolean">
        <label class="checkbox-label checkbox-label-disabled">
          <input type="radio" [checked]="model" disabled="true" />
          <span class="checkbox-custom"></span>
        </label>
      </div>
      <div *ngSwitchCase="'date'" class="date">
        {{ $any(model) | date: "medium" }}
      </div>
    </ng-container>
  </ng-template>
</ng-template>
