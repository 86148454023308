import { Component, Input } from "@angular/core";

import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent {
  @Input() title: string = null;
  @Input() unit: string = ""; // Displayed behind the title, representing a unit
  @Input() icon: IconDefinition;
  @Input() type?: "error" | "success" | "warning" | "info" | "default" =
    "default";
  @Input() shadow?: boolean = false;
  @Input() link?: string = null;

  public get cssConstructor() {
    return [
      this.type,
      this.shadow ? "shadow" : "border",
      this.icon ? "showIcon" : "",
    ];
  }
}
