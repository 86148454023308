import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FormsModule } from "@angular/forms";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { ButtonComponent } from "./components/button/button.component";
import { CheckboxComponent } from "./components/checkbox/checkbox.component";
import { SplitViewComponent } from "./components/split-view/split-view.component";
import { SelectComponent } from "./components/select/select.component";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SliderComponent } from "./components/slider/slider.component";
import { TabsComponent } from "./components/tabs/tabs.component";
import { TabComponent } from "./components/tabs/tab/tab.component";
import { RangeSliderComponent } from "./components/range-slider/range-slider.component";
import { VerticalSliderComponent } from "./components/vertical-slider/vertical-slider.component";
import { ModalComponent } from "./components/modal/modal.component";
import { HelpComponent } from "./components/help/help.component";
import { CellComponent } from "./components/data-table/cell/cell.component";
import { CardComponent } from "./components/card/card.component";
import { RouterModule } from "@angular/router";
import { ContentSwitchComponent } from "./components/content-switch/content-switch.component";
import { ContentComponent } from "./components/content-switch/content/content.component";
import { SkeletonTableComponent } from "./skeletons/table/table.component";
import { SkeletonHeadingComponent } from "./skeletons/heading/heading.component";
import { ToggleComponent } from "./components/toggle/toggle.component";
import { ConstraintsSliderComponent } from "./components/constraints-slider/constraints-slider.component";
import { RadioComponent } from './components/radio/radio.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CheckboxComponent,
    SplitViewComponent,
    SelectComponent,
    DataTableComponent,
    LoaderComponent,
    SliderComponent,
    TabsComponent,
    TabComponent,
    RangeSliderComponent,
    VerticalSliderComponent,
    ModalComponent,
    HelpComponent,
    CellComponent,
    CardComponent,
    ContentSwitchComponent,
    ContentComponent,
    SkeletonTableComponent,
    SkeletonHeadingComponent,
    ToggleComponent,
    ConstraintsSliderComponent,
    RadioComponent
  ],
  imports: [CommonModule, FormsModule, RouterModule, FontAwesomeModule],
  exports: [
    ButtonComponent,
    CheckboxComponent,
    SplitViewComponent,
    SelectComponent,
    DataTableComponent,
    LoaderComponent,
    SliderComponent,
    TabsComponent,
    TabComponent,
    RangeSliderComponent,
    VerticalSliderComponent,
    ModalComponent,
    HelpComponent,
    CardComponent,
    ContentSwitchComponent,
    ContentComponent,
    ToggleComponent,
    ConstraintsSliderComponent,
    RadioComponent,

    SkeletonTableComponent,
    SkeletonHeadingComponent,

    FontAwesomeModule,
  ],
})
export class SharedModule { }
