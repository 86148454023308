import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { ChangelogResponse } from "src/app/types/changelog.model";

@Injectable({
  providedIn: "root",
})
export class ChangelogService {
  public backendChangelog: string = null;

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(private http: HttpClient) {}

  public async getBackendData(): Promise<void> {
    this.http
      .get(`${this.requestScheme}${environment.backendUrl}/changelog`)
      .toPromise()
      .then((res: ChangelogResponse) => {
        this.backendChangelog = res.Changelog;
      })
      .catch((res: Response) => {
        console.error(`Could not get changelog data`);
      });
  }
}
