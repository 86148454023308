import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";

import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Check if the user is logged in.
    if (!this.authService.currentAuthState) {
      // if he is not, check the authStatus
      return this.authService.authStatus.pipe(
        take(1),
        map((value) => {
          if (value === true) {
            return true;
          } else if (value === false) {
            // If the user is not logged in, go to the auth page.
            // "ar" in the query params is for "auth return" and let's the auth page 
            // redirect to the last visited page before login
            this.router.navigate(["/auth"], { queryParams: { ar: state.url } });
            return false;
          }
        })
      );
    } else {
      // If the user is logged in he can access this route.
      return true;
    }
  }
}
