import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public _authStatus: ReplaySubject<boolean> = new ReplaySubject();
  /**
   * Observable for the user auth state. True if the user is logged in.
   */
  public readonly authStatus: Observable<boolean> = this._authStatus.asObservable();

  // I don't know how fast the observable updates currentAuthState.
  // It could potentially be some milliseconds behind the actual observable (authStatus)

  /**
   * Represents the current auth state as seen by observables of "authState".
   * With this you don't need to subscribe to authStatus.
   */
  public currentAuthState: boolean = null;

  private requestScheme =
    environment.requestScheme === "secure" ? "https://" : "http://";

  constructor(private router: Router) {
    // Check if there is a valid auth cookie stored in the Frontend.
    this.validateToken()
      .then(() => {
        // If the cookie is valid, set the new authState to true
        this._authStatus.next(true);
      })
      .catch((err) => {
        // The token is not valid. A user must login
        this._authStatus.next(false);
      });

    // Subscription to set "currentAuthState"
    this.authStatus.subscribe((next) => {
      this.currentAuthState = next;
    });
  }

  /**
   * Try to login a user using the provided credentials
   */
  public async login(username: string, password: string): Promise<any> {
    // Check if username and password are provided
    // TODO: Also check if credentials are valid
    if (password !== "" && username !== "") {
      // Uses the fetch api instead of the HTTP client to bypass the HttpInterceptor that sends an auth token
      const res = await fetch(
        `${this.requestScheme}${environment.backendUrl}/login`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: `username=${username}&password=${password}`,
        }
      );

      if (res.ok) {
        this._authStatus.next(true);
        return Promise.resolve();
      }
    }

    // If the login was unsuccessfull
    this._authStatus.next(false);
    return Promise.reject("Failed to log in");
  }

  /**
   * Logout the current user and redirect to the start page.
   */
  public async logout(): Promise<any> {
    const res = await fetch(
      `${this.requestScheme}${environment.backendUrl}/logout`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (res && res.ok) {
      this._authStatus.next(false);
      this.router.navigate(["/"]).then(() => {
        // This ensures everything is reset.
        location.reload();
      });
    }
  }

  /**
   * Validates the token saved inside the mops_jwt cookie agains backend.
   */
  public async validateToken(): Promise<boolean> {
    const res = await fetch(
      `${this.requestScheme}${environment.backendUrl}/verify-token`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (res && res.ok) {
      return Promise.resolve(true);
    } else {
      return Promise.reject(res.statusText);
    }
  }
}
