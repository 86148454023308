import { Storable } from "./data-store.model";

export type ProcessParameterType = "solid" | "gas";
export type ProcessParameterSource = "bucket" | "burner" | "feeding";
export type ProcessParameterResultUnit = "kg" | "m^3";

export enum ProcessParameterUnit {
  kg_kg_steel = "kg/kg_steel",
  kg_ton_steel = "kg/ton_steel",
  kg_s = "kg/s",
  kg_m = "kg/m",
  m3_h = "m^3/h",
  m3_s = "m^3/s",
}

export interface SolidParameterConcentration {
  MaterialId: number;
  ElementId: number;
  Value: number;
}

export interface ProcessParameter extends Storable {
  Id: number;
  Name: string;
  Cost: number;
  Type: ProcessParameterType;
  Unit: ProcessParameterUnit;
  Concentrations?: SolidParameterConcentration[];
}

export interface DefaultParameter {
  Id: number;
  Name: string;
  Source: ProcessParameterSource;
  Amount: number;
  Unit: ProcessParameterUnit;
}

export interface DefaultParameterResponse {
  Burner: DefaultParameter[];
  Bucket: DefaultParameter[];
  Feeding: DefaultParameter[];
}

export interface ShortParameter {
  MaterialId: number;
  Source: ProcessParameterSource;
  Amount: number;
  Unit: ProcessParameterUnit;
}

export interface DefaultParameterUpdate {
  MaterialId: number;
  Source: ProcessParameterSource;
  Amount: number;
  Unit: ProcessParameterUnit;
}
