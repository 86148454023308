import { Component, Input } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "sms-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() type!: "error" | "success" | "warning" | "info" | "default";
  @Input() disabled?: boolean = false;
}
